import { CalendarCheck } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { formatTime } from '../../lib/utils';

export default function ReservationModal({ 
  open, 
  onOpenChange, 
  reservations,
  onViewAll 
}) {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}년 ${month}월 ${day}일`;
  };

  // 예정된 예약만 필터링하는 함수
  const filterUpcomingReservations = (reservations) => {
    // LA 시간 기준으로 현재 시간 가져오기
    const now = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    
    // 투어 필터링
    const upcomingTours = reservations.tours.filter(tour => {
      if (tour.status !== 'APPROVED') return false;
      const targetDateStr = `${tour.available_tour.event_date.date} ${tour.available_tour.time}`;
      return new Date(targetDateStr) > new Date(now);
    });
  
    // 미팅룸 필터링
    const upcomingMeetings = reservations.meetings.filter(meeting => {
      if (meeting.status !== 'APPROVED') return false;
      const firstTimeSlot = meeting.time_slots?.[0];
      if (!firstTimeSlot) return false;
      
      const targetDateStr = `${meeting.event_date.date} ${firstTimeSlot.start_time}`;
      return new Date(targetDateStr) > new Date(now);
    });
  
    return {
      tours: upcomingTours,
      meetings: upcomingMeetings
    };
  };

  const upcomingReservations = filterUpcomingReservations(reservations);
  const hasUpcomingReservations = upcomingReservations.tours.length > 0 || upcomingReservations.meetings.length > 0;

  if (!hasUpcomingReservations) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <CalendarCheck className="w-5 h-5" />
            예정된 예약 안내
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 max-h-[60vh] overflow-y-auto">
          {upcomingReservations.tours.length > 0 && (
            <div className="space-y-2">
              <h3 className="font-medium text-gray-900">VIP 투어 예약</h3>
              {upcomingReservations.tours
                .sort((a, b) => {
                  const dateA = new Date(a.available_tour.event_date.date);
                  const dateB = new Date(b.available_tour.event_date.date);
                  return dateA - dateB;
                })
                .map((tour) => (
                <div key={tour.id} className="p-3 text-sm rounded-lg bg-sky-50">
                  <div className="flex justify-between text-sky-900">
                    <span>{formatDate(tour.available_tour.event_date.date)}</span>
                    <span>{formatTime(tour.available_tour.time)}</span>
                  </div>
                  <div className="mt-1 text-gray-600">
                    도슨트: {tour.available_tour.docent.name}
                  </div>
                </div>
              ))}
            </div>
          )}
          
          {upcomingReservations.meetings.length > 0 && (
            <div className="space-y-2">
              <h3 className="font-medium text-gray-900">미팅룸 예약</h3>
              {upcomingReservations.meetings
                .sort((a, b) => {
                  const dateA = new Date(a.event_date.date);
                  const dateB = new Date(b.event_date.date);
                  return dateA - dateB;
                })
                .map((meeting) => (
                  <div key={meeting.id} className="p-3 text-sm rounded-lg bg-sky-50">
                    <div className="flex justify-between text-sky-900">
                      <span>{formatDate(meeting.event_date.date)}</span>
                      <span>{meeting.time_range}</span>
                    </div>
                    <div className="mt-1 text-gray-600">
                      미팅룸: {meeting.room.name}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 mt-6">
          <button
            className="px-4 py-2 text-sm font-medium text-gray-700 transition-colors bg-white border rounded-md hover:bg-gray-50"
            onClick={() => onOpenChange(false)}
          >
            닫기
          </button>
          <button
            className="px-4 py-2 text-sm font-medium text-white transition-colors rounded-md bg-sky-700 hover:bg-sky-800"
            onClick={onViewAll}
          >
            전체 예약 내역 보기
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}