import { useState, useEffect, useCallback } from 'react';
import { Trash2, Pencil, X, Building2, User, Phone, Briefcase, Download } from "lucide-react";
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Card } from '../../ui/card'

// 전화번호 형식화 함수
const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  
  // 숫자만 추출
  const numbers = phone.replace(/[^0-9]/g, '');
  
  // 010-1234-5678 형식으로 변환
  if (numbers.length === 11) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7)}`;
  }
  return phone;
};

// 전화번호 유효성 검사 함수
const isValidPhoneNumber = (phone) => {
  const numbers = phone.replace(/-/g, '');
  return /^010\d{8}$/.test(numbers);
};

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [userFilter, setUserFilter] = useState('all');

  // 사용자 목록 조회
  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/api/admin/users/', {
        params: {
          search: searchQuery,
          page: page,
          user_filter: userFilter
        }
      });
      
      if (response.data) {
        const results = Array.isArray(response.data) ? response.data : response.data.results || [];
        setUsers(results);
        const totalCount = Array.isArray(response.data) ? response.data.length : (response.data.count || 0);
        setTotalItems(totalCount);
        setTotalPages(Math.ceil(totalCount / 10));
        setError('');
      } else {
        setUsers([]);
        setTotalItems(0);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('사용자 목록을 불러오는데 실패했습니다.');
      setUsers([]);
      setTotalItems(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, page, userFilter]);

  const handleExportExcel = async () => {
    try {
      const response = await api.get('/api/admin/users/export/', {
        params: { search: searchQuery },
        responseType: 'blob'
      });
      
      // 파일 다운로드
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `사용자목록_${new Date().toISOString().split('T')[0]}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      toast.success('사용자 목록이 다운로드되었습니다.');
    } catch (error) {
      toast.error('엑셀 파일 다운로드에 실패했습니다.');
      console.error('Export error:', error);
    }
  };

  // 검색 핸들러
  const handleSearch = (value) => {
    setSearchQuery(value);
    setPage(1); // 검색 시 첫 페이지로 초기화
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // 페이지 변경 핸들러
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSelectedUserIds([]); // 페이지 변경 시 선택 초기화
  };

  // 전체 선택 처리
  const handleSelectAll = (e) => {
    if (e.target.checked && users) {
      setSelectedUserIds(users.map(user => user.id));
    } else {
      setSelectedUserIds([]);
    }
  };

  // 개별 선택 처리
  const handleSelectUser = (userId) => {
    setSelectedUserIds(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  // 선택된 사용자 삭제
  const handleBulkDelete = async () => {
    if (selectedUserIds.length === 0) return;
    
    const confirmMessage = selectedUserIds.length === users.length
      ? '모든 사용자를 삭제하시겠습니까?'
      : `선택한 ${selectedUserIds.length}명의 사용자를 삭제하시겠습니까?`;
    
    if (!window.confirm(confirmMessage)) return;

    try {
      await api.delete('/api/admin/users/', {
        data: { user_ids: selectedUserIds }
      });
      setSelectedUserIds([]);
      await fetchUsers(); // await 추가
      toast.success('선택한 사용자들이 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 전체 사용자 삭제
  const handleDeleteAll = async () => {
    if (!window.confirm('정말로 모든 사용자를 삭제하시겠습니까?')) return;

    try {
      await api.delete('/api/admin/users/');
      setPage(1);
      await fetchUsers();
      toast.success('모든 사용자가 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 단일 사용자 삭제
  const handleDelete = async (userId) => {
    if (!window.confirm('정말로 이 사용자를 삭제하시겠습니까?')) return;

    try {
      await api.delete(`/api/admin/users/${userId}/`);
      await fetchUsers();
      toast.success('사용자가 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 사용자 수정 모달 열기
  const openEditModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-8">
        {/* 헤더 섹션 */}
        <div className="mb-8 bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-medium text-gray-900">회원 관리</h2>
          </div>
          
          <div className="p-4 sm:p-6">
            {/* 모바일에서는 세로로, PC에서는 가로로 배치 */}
            <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
              {/* 필터와 검색 그룹 */}
              <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
                {/* VIP 필터 드롭다운 */}
                <select
                  value={userFilter}
                  onChange={(e) => {
                    setUserFilter(e.target.value);
                    setPage(1);
                  }}
                  className="px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500"
                >
                  <option value="all">전체 회원</option>
                  <option value="vvip">VVIP 회원</option>
                  <option value="vip">VIP 회원</option>
                  <option value="normal">일반 회원</option>
                </select>
                
                {/* 검색창 */}
                <div className="w-full sm:w-96">
                  <input
                    type="text"
                    placeholder="이름, 회사, 이메일, 전화번호로 검색"
                    className="w-full py-2 pl-4 pr-4 text-sm border border-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
              </div>
              
              {/* 액션 버튼들 */}
              <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                {selectedUserIds.length > 0 && (
                  <button
                    onClick={handleBulkDelete}
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    선택 삭제 ({selectedUserIds.length})
                  </button>
                )}
                <button
                  onClick={handleDeleteAll}
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <Trash2 className="w-4 h-4 mr-2" />
                  전체 삭제
                </button>
                <button
                  onClick={handleExportExcel}
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <Download className="w-4 h-4 mr-2" />
                  Excel 다운로드
                </button>
              </div>
            </div>
          </div>
        </div>
  
        {error && (
          <div className="p-4 mb-6 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}
  
        {/* 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3 pl-6">
                    <input
                      type="checkbox"
                      checked={users && users.length > 0 && selectedUserIds.length === users.length}
                      onChange={handleSelectAll}
                      disabled={!users || users.length === 0}
                      className="w-4 h-4 border-gray-300 rounded text-sky-600 focus:ring-sky-500"
                    />
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    회사명
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    이름
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    연락처
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    직책
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    이메일
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    회원등급
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase bg-gray-50">
                    관리
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      <div className="flex items-center justify-center">
                        <div className="w-6 h-6 border-t-2 border-b-2 rounded-full border-sky-500 animate-spin"></div>
                        <span className="ml-2">로딩 중...</span>
                      </div>
                    </td>
                  </tr>
                ) : !users || users.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                      등록된 회원이 없습니다.
                    </td>
                  </tr>
                ) : (
                  users.map((user) => (
                    <tr key={user.id || user.email} className="hover:bg-gray-50">
                      <td className="py-4 pl-6">
                        <input
                          type="checkbox"
                          checked={selectedUserIds.includes(user.id)}
                          onChange={() => handleSelectUser(user.id)}
                          className="w-4 h-4 border-gray-300 rounded text-sky-600 focus:ring-sky-500"
                        />
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.company_name || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.name || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {formatPhoneNumber(user.phone) || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.position || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.email || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.is_vvip ? (
                          <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-purple-700 bg-purple-100 rounded-full">
                            VVIP
                          </span>
                        ) : user.is_vip ? (
                          <span className="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full text-sky-700 bg-sky-100">
                            VIP
                          </span>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-center">
                        <div className="flex justify-center space-x-3">
                          <button
                            onClick={() => openEditModal(user)}
                            className="transition-colors text-sky-600 hover:text-sky-900"
                            title="수정"
                          >
                            <Pencil className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDelete(user.id)}
                            className="text-red-600 transition-colors hover:text-red-900"
                            title="삭제"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
  
          {/* 페이지네이션 */}
          <div className="px-4 py-4 border-t border-gray-200 sm:px-6 bg-gray-50">
            <div className="flex flex-col items-center space-y-3 sm:flex-row sm:justify-between sm:space-y-0">
              {/* 페이지 정보 */}
              <div className="text-sm text-gray-700">
                <p className="text-center sm:text-left">
                  전체 <span className="font-medium">{totalItems}</span> 명
                  {totalItems > 0 && (
                    <span className="hidden sm:inline">
                      중 <span className="font-medium">{((page - 1) * 10) + 1}</span> 부터{' '}
                      <span className="font-medium">{Math.min(page * 10, totalItems)}</span> 까지
                    </span>
                  )}
                </p>
              </div>
              
              {totalPages > 1 && (
                <div className="flex items-center justify-center space-x-1">
                  {/* 처음 버튼 - 모바일에서는 숨김 */}
                  <button
                    onClick={() => handlePageChange(1)}
                    disabled={page === 1}
                    className={`hidden sm:block px-2 py-1 text-sm rounded-md ${
                      page === 1
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    처음
                  </button>
                  
                  {/* 이전 버튼 */}
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === 1
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    이전
                  </button>

                  {/* 페이지 번호 */}
                  <div className="flex items-center space-x-1">
                    {[...Array(totalPages)].map((_, i) => {
                      // 모바일에서는 현재 페이지와 그 주변 1페이지만 표시
                      // PC에서는 현재 페이지와 그 주변 2페이지 표시
                      const isMobile = typeof window !== 'undefined' && window.innerWidth < 640;
                      const showInMobile = i + 1 === 1 || 
                                        i + 1 === totalPages || 
                                        (i + 1 >= page - 1 && i + 1 <= page + 1);
                      const showInDesktop = i + 1 === 1 || 
                                          i + 1 === totalPages || 
                                          (i + 1 >= page - 2 && i + 1 <= page + 2);
                      
                      if ((isMobile && showInMobile) || (!isMobile && showInDesktop)) {
                        return (
                          <button
                            key={i}
                            onClick={() => handlePageChange(i + 1)}
                            className={`min-w-[32px] px-2 py-1 text-sm rounded-md ${
                              page === i + 1
                                ? 'bg-sky-600 text-white'
                                : 'text-gray-700 hover:bg-gray-100'
                            }`}
                          >
                            {i + 1}
                          </button>
                        );
                      }
                      
                      // 생략 부호 표시 조건 수정
                      if ((isMobile && (i + 1 === page - 2 || i + 1 === page + 2)) ||
                          (!isMobile && (i + 1 === page - 3 || i + 1 === page + 3))) {
                        return (
                          <span key={i} className="px-1 text-gray-500">
                            •••
                          </span>
                        );
                      }
                      
                      return null;
                    })}
                  </div>

                  {/* 다음 버튼 */}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === totalPages
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    다음
                  </button>
                  
                  {/* 마지막 버튼 - 모바일에서는 숨김 */}
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={page === totalPages}
                    className={`hidden sm:block px-2 py-1 text-sm rounded-md ${
                      page === totalPages
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    마지막
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 수정 모달 */}
      {showModal && (
        <UserEditModal
          user={selectedUser}
          onClose={() => {
            setShowModal(false);
            setSelectedUser(null);
          }}
          onSave={async (updatedData) => {
            try {
              await api.put(`/api/admin/users/${selectedUser.id}/`, updatedData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              await fetchUsers(); // await 추가
              setShowModal(false);
              setSelectedUser(null);
              toast.success('사용자 정보가 수정되었습니다.');
            } catch (error) {
              console.error('Error:', error.response?.data || error);
              toast.error('사용자 정보 수정에 실패했습니다.');
            }
          }}
        />
      )}
    </div>
  );
}

// 사용자 수정 모달 컴포넌트
function UserEditModal({ user, onClose, onSave }) {
  const [formData, setFormData] = useState({
    id: user.id,
    name: user.name || '',
    email: user.email || '',
    phone: formatPhoneNumber(user.phone) || '',
    company_name: user.company_name || '',
    position: user.position || '',
    is_vip: user.is_vip || false,
    is_vvip: user.is_vvip || false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error('올바른 전화번호 형식이 아닙니다.');
      return;
    }

    onSave({
      ...formData,
    });
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d-]/g, '');
    const numbers = value.replace(/-/g, '');
    
    if (numbers.length <= 11) {
      let formattedNumber = '';
      if (numbers.length > 3) {
        formattedNumber += numbers.substring(0, 3) + '-';
        if (numbers.length > 7) {
          formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
        } else {
          formattedNumber += numbers.substring(3);
        }
      } else {
        formattedNumber = numbers;
      }
      setFormData(prev => ({ ...prev, phone: formattedNumber }));
    }
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;
    setFormData(current => ({
      ...current,
      [field]: value
    }));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-md overflow-hidden">
        <div className="flex items-center justify-between p-6 bg-white border-b">
          <div className="flex items-center gap-2">
            <User className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">사용자 정보 수정</h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-4 bg-white">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  VVIP 상태
                </label>
                <button
                  type="button"
                  onClick={() => {
                    const newVvip = !formData.is_vvip;
                    setFormData(prev => ({
                      ...prev,
                      is_vvip: newVvip,
                      // VVIP가 활성화되면 자동으로 VIP도 활성화
                      is_vip: newVvip ? true : prev.is_vip
                    }));
                  }}
                  className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                    formData.is_vvip ? 'bg-purple-600' : 'bg-gray-200'
                  }`}
                >
                  <span
                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                      formData.is_vvip ? 'translate-x-6' : 'translate-x-1'
                    }`}
                  />
                </button>
              </div>
              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  VIP 상태
                </label>
                <button
                  type="button"
                  onClick={() => {
                    const newVip = !formData.is_vip;
                    setFormData(prev => ({
                      ...prev,
                      is_vip: newVip,
                      // VIP가 비활성화되면 VVIP도 비활성화
                      is_vvip: newVip ? prev.is_vvip : false
                    }));
                  }}
                  disabled={formData.is_vvip} // VVIP가 활성화되어 있으면 VIP 토글 비활성화
                  className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                    formData.is_vip ? 'bg-sky-600' : 'bg-gray-200'
                  } ${formData.is_vvip ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <span
                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                      formData.is_vip ? 'translate-x-6' : 'translate-x-1'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Building2 className="w-4 h-4 text-gray-500" />
                회사명
              </label>
              <input
                type="text"
                value={formData.company_name}
                onChange={handleInputChange('company_name')}
                placeholder="회사명을 입력하세요"
                className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
              />
            </div>
            
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="w-4 h-4 text-gray-500" />
                이름
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={handleInputChange('name')}
                placeholder="이름을 입력하세요"
                className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
              />
            </div>
            
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Phone className="w-4 h-4 text-gray-500" />
                연락처
              </label>
              <input
                type="text"
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="010-0000-0000"
                className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
              />
            </div>
            
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Briefcase className="w-4 h-4 text-gray-500" />
                직책
              </label>
              <input
                type="text"
                value={formData.position}
                onChange={handleInputChange('position')}
                placeholder="직책을 입력하세요"
                className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
              />
            </div>

            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Briefcase className="w-4 h-4 text-gray-500" />
                이메일
              </label>
              <input
                type="text"
                value={formData.email}
                onChange={handleInputChange('email')}
                placeholder="이메일을 입력하세요"
                className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
              />
            </div>
          </div>

          <div className="flex items-center justify-end gap-3 px-6 py-4 border-t bg-gray-50">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 transition-colors bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
            >
              취소
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white transition-colors rounded-lg bg-sky-600 hover:bg-sky-700"
            >
              저장
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
}