import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Lock, Unlock, Trash2, ChevronDown, ChevronUp, Info } from 'lucide-react';

export default function ResourceAvailabilityManagement({ type }) {
  // 기본 상태 관리
  const [resources, setResources] = useState([]); // 도슨트 또는 미팅룸 목록
  const [dates, setDates] = useState([]); // 날짜 목록
  const [selectedResource, setSelectedResource] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [allAvailableSlots, setAllAvailableSlots] = useState([]);
  const [groupedByDate, setGroupedByDate] = useState({});
  const [searchResource, setSearchResource] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [selectedSlots, setSelectedSlots] = useState([]);
  
  // 시간 생성 관련 상태
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [interval, setInterval] = useState(type === 'tour' ? 30 : 10);
  const [duration, setDuration] = useState(type === 'tour' ? 30 : 10);
  const [isLoading, setIsLoading] = useState(false);
  const [isBulkFormOpen, setIsBulkFormOpen] = useState(false);
  const [isSingleFormOpen, setIsSingleFormOpen] = useState(false);
  const [singleTime, setSingleTime] = useState('09:00');

  // 일괄 잠금/해제 처리 함수
  const handleBulkLockToggle = async (action) => {
    const message = action === 'lock' ? '잠금' : '해제';
    const confirmMessage = searchResource || searchDate
      ? `필터링된 시간대를 모두 ${message} 처리하시겠습니까?`
      : `모든 시간대를 ${message} 처리하시겠습니까?`;

    if (!window.confirm(confirmMessage)) return;

    try {
      await api.patch('/api/admin/bulk-availability/', {
        type: type === 'tour' ? 'tour' : 'room',
        resource_id: searchResource || null,
        date: searchDate || null,
        action: action
      });

      toast.success(`선택된 시간대가 ${message} 처리되었습니다.`);
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error bulk toggling lock:', error);
      toast.error(`시간대 ${message} 처리에 실패했습니다.`);
    }
  };
  
  const handleSelectAll = (slots, isSelected) => {
    if (isSelected) {
      const newSlotIds = slots
        .filter(slot => !selectedSlots.includes(slot.id))
        .map(slot => slot.id);
      setSelectedSlots(prev => [...prev, ...newSlotIds]);
    } else {
      const slotIds = slots.map(slot => slot.id);
      setSelectedSlots(prev => prev.filter(id => !slotIds.includes(id)));
    }
  };

  const handleSlotSelection = (slotId) => {
    setSelectedSlots(prev => {
      if (prev.includes(slotId)) {
        return prev.filter(id => id !== slotId);
      }
      return [...prev, slotId];
    });
  };

  const handleLockToggle = async (action) => {
    if (selectedSlots.length === 0) {
      toast.error('선택된 시간대가 없습니다.');
      return;
    }

    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/'
        : '/api/admin/available-rooms/';

      await api.patch(endpoint, {
        time_slot_ids: selectedSlots,
        action: action
      });

      toast.success(`선택된 시간대가 ${action === 'lock' ? '잠금' : '해제'} 처리되었습니다.`);
      setSelectedSlots([]);
      await fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error toggling lock:', error);
      toast.error(`시간대 ${action === 'lock' ? '잠금' : '해제'}에 실패했습니다.`);
    }
  };

  // 초기 데이터 로드
  useEffect(() => {
    fetchResources();
    fetchDates();
    fetchAllAvailableSlots();
  }, [type]);

  // 검색 조건이 변경될 때마다 필터링
  useEffect(() => {
    const filtered = allAvailableSlots.reduce((acc, slot) => {
      const resource = type === 'tour' ? slot.docent : slot.room;
      const date = type === 'tour' ? slot.event_date.date : slot.event_date.date;
      
      if (searchResource && resource.id.toString() !== searchResource) return acc;
      if (searchDate && date !== searchDate) return acc;
      
      if (!acc[date]) {
        acc[date] = {};
      }
      
      if (!acc[date][resource.name]) {
        acc[date][resource.name] = {
          resourceId: resource.id,
          floor: type === 'tour' ? null : resource.floor,
          size: type === 'tour' ? null : resource.size,
          slots: []
        };
      }
      
      acc[date][resource.name].slots.push({
        id: slot.id,
        time: slot.time,
        duration: slot.duration,
        is_locked: slot.is_locked,
        available: slot.available
      });
      
      acc[date][resource.name].slots.sort((a, b) => a.time.localeCompare(b.time));
      
      return acc;
    }, {});
  
    const orderedDates = Object.keys(filtered)
      .sort()
      .reduce((acc, date) => {
        acc[date] = Object.keys(filtered[date])
          .sort()
          .reduce((resourceAcc, resourceName) => {
            resourceAcc[resourceName] = filtered[date][resourceName];
            return resourceAcc;
          }, {});
        return acc;
      }, {});
  
    setGroupedByDate(orderedDates);
  }, [searchResource, searchDate, allAvailableSlots]);

  const fetchResources = async () => {
    try {
      const endpoint = type === 'tour' ? '/api/admin/docents/' : '/api/admin/rooms/';
      const response = await api.get(endpoint);
      setResources(response.data);
    } catch (error) {
      console.error('Error fetching resources:', error);
      toast.error('목록을 불러오는데 실패했습니다.');
    }
  };

  const fetchDates = async () => {
    try {
      const response = await api.get('/api/admin/event-dates/');
      setDates(response.data);
    } catch (error) {
      console.error('Error fetching dates:', error);
      toast.error('날짜 목록을 불러오는데 실패했습니다.');
    }
  };

  const fetchAllAvailableSlots = async () => {
    try {
      const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
      const response = await api.get(endpoint);
      setAllAvailableSlots(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      toast.error('가용 시간 목록을 불러오는데 실패했습니다.');
    }
  };

  // 가용 시간 일괄 생성 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (startTime >= endTime) {
      toast.error('시작 시간은 종료 시간보다 빨라야 합니다.');
      setIsLoading(false);
      return;
    }

    if (!selectedResource || !selectedDate) {
      toast.error('리소스와 날짜를 선택해주세요.');
      setIsLoading(false);
      return;
    }

    try {
      const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
      const dateId = dates.find(d => d.date === selectedDate)?.id;

      if (!dateId) {
        toast.error('유효하지 않은 날짜입니다.');
        return;
      }

      await api.post(endpoint, {
        [`${type === 'tour' ? 'docent' : 'room'}_id`]: selectedResource,
        event_date_id: dateId,
        start_time: startTime,
        end_time: endTime,
        interval_minutes: parseInt(interval),
        duration: parseInt(duration),
      });

      toast.success('가용 시간이 설정되었습니다.');
      await fetchAllAvailableSlots();
    } catch (error) {
      toast.error('가용 시간 설정에 실패했습니다.');
      console.error('Error creating availability:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSlot = async (id) => {
    if (!window.confirm('선택한 가용 시간을 삭제하시겠습니까?')) return;
  
    try {
      const endpoint = type === 'tour' 
        ? `/api/admin/available-tours/${id}/` 
        : `/api/admin/available-rooms/${id}/`;
      
      await api.delete(endpoint);
      toast.success('가용 시간이 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting slot:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };
  
  const handleDeleteResourceSlots = async (resourceId, date) => {
    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/' 
        : '/api/admin/available-rooms/';
      
      const params = {
        [type === 'tour' ? 'docent_id' : 'room_id']: resourceId,
        date: date
      };
  
      await api.delete(endpoint, { params });
      toast.success('가용 시간이 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting resource slots:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };
  
  const handleDeleteFilteredSlots = async () => {
    const message = searchResource || searchDate
      ? '필터링된 가용 시간을 모두 삭제하시겠습니까?'
      : '모든 가용 시간을 삭제하시겠습니까?';
  
    if (!window.confirm(message)) return;
  
    try {
      const endpoint = type === 'tour' 
        ? '/api/admin/available-tours/' 
        : '/api/admin/available-rooms/';
      
      const params = {};
      if (searchResource) {
        params[type === 'tour' ? 'docent_id' : 'room_id'] = searchResource;
      }
      if (searchDate) {
        params.date = searchDate;
      }
  
      await api.delete(endpoint, { params });
      toast.success('선택된 가용 시간이 모두 삭제되었습니다.');
      fetchAllAvailableSlots();
    } catch (error) {
      console.error('Error deleting filtered slots:', error);
      toast.error('가용 시간 삭제에 실패했습니다.');
    }
  };

  // 단일 가용 시간 생성 처리
  const handleSingleSubmit = async (e) => {
    e.preventDefault();
    if (type !== 'tour') return;  // 타입 체크 추가
    setIsLoading(true);

    if (!selectedResource || !selectedDate) {
      toast.error('리소스와 날짜를 선택해주세요.');
      setIsLoading(false);
      return;
    }

    try {
      const endpoint = '/api/admin/available-tours/single/';
      const dateId = dates.find(d => d.date === selectedDate)?.id;

      if (!dateId) {
        toast.error('유효하지 않은 날짜입니다.');
        return;
      }

      await api.post(endpoint, {
        docent_id: selectedResource,
        event_date_id: dateId,
        time: singleTime,
        duration: parseInt(duration),
      });

      toast.success('가용 시간이 추가되었습니다.');
      await fetchAllAvailableSlots();
      
      // 폼 초기화
      setSingleTime('09:00');
    } catch (error) {
      if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('가용 시간 추가에 실패했습니다.');
      }
      console.error('Error creating single availability:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* 일괄 생성 섹션 */}
      <div className="bg-white rounded-lg shadow">
        <button
          onClick={() => setIsBulkFormOpen(!isBulkFormOpen)}
          className="flex items-center justify-between w-full p-6 text-xl font-semibold"
        >
          <span>
            {type === 'tour' ? '도슨트 가용 시간 일괄 생성' : '미팅룸 가용 시간 생성'}
          </span>
          {isBulkFormOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </button>
        
        {isBulkFormOpen && (
          <div className="p-6 pt-0">
            <div className="flex items-start gap-2 p-4 mb-6 text-sm text-yellow-800 border border-yellow-200 rounded-lg bg-yellow-50">
              <Info size={20} className="flex-shrink-0 mt-0.5" />
              <p>
                {type === 'tour' 
                  ? '초기 시간표 설정 시에만 사용하세요. 이 기능을 사용하면 선택한 도슨트의 해당 날짜의 기존 가용 시간이 모두 삭제됩니다.' 
                  : '초기 시간표 설정 시에만 사용하세요. 이 기능을 사용하면 선택한 미팅룸의 해당 날짜의 기존 가용 시간이 모두 삭제됩니다.'}
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    {type === 'tour' ? '도슨트' : '미팅룸'} 선택
                  </label>
                  <select
                    value={selectedResource}
                    onChange={(e) => setSelectedResource(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    required
                  >
                    <option value="">선택해주세요</option>
                    {resources.map((resource) => (
                      <option key={resource.id} value={resource.id}>
                        {type === 'tour' 
                          ? resource.name 
                          : `${resource.name} (${resource.floor}층 ${resource.size}인실)`}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    날짜 선택
                  </label>
                  <select
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    required
                  >
                    <option value="">선택해주세요</option>
                    {dates.map((date) => (
                      <option key={date.id} value={date.date}>
                        {date.date}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    시작 시간
                  </label>
                  <input
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    종료 시간
                  </label>
                  <input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    {type === 'tour' ? '예약' : '시간'} 간격 (분)
                  </label>
                  <input
                    type="number"
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    min="1"
                    required
                  />
                </div>
                {type === 'tour' && (
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    투어 소요시간 (분)
                  </label>
                  <input
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    min="1"
                    required
                  />
                </div>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`px-6 py-2 text-white rounded-md ${
                    isLoading 
                      ? 'bg-blue-400 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isLoading ? '생성 중...' : type === 'tour' ? '일괄 생성' : '가용 시간 생성'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* 단일 생성 섹션 */}
      {type === 'tour' && (
        <div className="bg-white rounded-lg shadow">
          <button
            onClick={() => setIsSingleFormOpen(!isSingleFormOpen)}
            className="flex items-center justify-between w-full p-6 text-xl font-semibold"
          >
            <span>도슨트 단일 가용 시간 추가</span>
            {isSingleFormOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
          
          {isSingleFormOpen && (
            <div className="p-6 pt-0">
              <div className="flex items-start gap-2 p-4 mb-6 text-sm text-blue-800 border border-blue-200 rounded-lg bg-blue-50">
                <Info size={20} className="flex-shrink-0 mt-0.5" />
                <p>
                  특정 시간대를 추가할 때 사용하세요. 기존 가용 시간은 유지됩니다.
                </p>
              </div>

              <form onSubmit={handleSingleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      도슨트 선택
                    </label>
                    <select
                      value={selectedResource}
                      onChange={(e) => setSelectedResource(e.target.value)}
                      className="w-full px-4 py-2 border rounded-md"
                      required
                    >
                      <option value="">선택해주세요</option>
                      {resources.map((resource) => (
                        <option key={resource.id} value={resource.id}>
                          {resource.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      날짜 선택
                    </label>
                    <select
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      className="w-full px-4 py-2 border rounded-md"
                      required
                    >
                      <option value="">선택해주세요</option>
                      {dates.map((date) => (
                        <option key={date.id} value={date.date}>
                          {date.date}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      시간
                    </label>
                    <input
                      type="time"
                      value={singleTime}
                      onChange={(e) => setSingleTime(e.target.value)}
                      className="w-full px-4 py-2 border rounded-md"
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      투어 소요시간 (분)
                    </label>
                    <input
                      type="number"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      className="w-full px-4 py-2 border rounded-md"
                      min="1"
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`px-6 py-2 text-white rounded-md ${
                      isLoading 
                        ? 'bg-blue-400 cursor-not-allowed' 
                        : 'bg-blue-600 hover:bg-blue-700'
                    }`}
                  >
                    {isLoading ? '추가 중...' : '시간 추가'}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
      {/* 가용 시간 목록 섹션 */}
      <div className="p-6 bg-white rounded-lg shadow">
        <div className="flex flex-col mb-6 space-y-4 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-medium">설정된 가용 시간 목록</h3>
            {selectedSlots.length > 0 && (
              <span className="text-sm text-gray-500">
                {selectedSlots.length}개 선택됨
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:gap-4 lg:space-y-0">
            <div className="flex flex-col space-y-2 sm:flex-row sm:gap-2 sm:space-y-0">
              <select
                value={searchResource}
                onChange={(e) => setSearchResource(e.target.value)}
                className="w-full px-4 py-2 border rounded-md sm:w-auto"
              >
                <option value="">전체 {type === 'tour' ? '도슨트' : '미팅룸'}</option>
                {resources.map((resource) => (
                  <option key={resource.id} value={resource.id}>
                    {resource.name}
                  </option>
                ))}
              </select>
              <select
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                className="w-full px-4 py-2 border rounded-md sm:w-auto"
              >
                <option value="">전체 날짜</option>
                {dates.map((date) => (
                  <option key={date.id} value={date.date}>
                    {date.date}
                  </option>
                ))}
              </select>
            </div>
            {selectedSlots.length > 0 ? (
              <div className="flex w-full gap-2 sm:w-auto">
                <button
                  onClick={() => handleLockToggle('unlock')}
                  className="flex items-center justify-center flex-1 gap-2 px-4 py-2 text-blue-600 transition-colors rounded-md bg-blue-50 hover:bg-blue-100 sm:flex-initial"
                >
                  <Unlock size={16} />
                  해제
                </button>
                <button
                  onClick={() => handleLockToggle('lock')}
                  className="flex items-center justify-center flex-1 gap-2 px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700 sm:flex-initial"
                >
                  <Lock size={16} />
                  잠금
                </button>
              </div>
            ) : (
              <div className="flex flex-col space-y-2 sm:flex-row sm:gap-2 sm:space-y-0">
                <button
                  onClick={() => {
                    if (!searchResource && !searchDate) {
                      if (!window.confirm('주의: 필터링하지 않은 전체 해제는 모든 날짜, 모든 시간대의 예약이 취소될 수 있으며 이 작업은 되돌릴 수 없습니다. 계속하시겠습니까?')) {
                        return;
                      }
                    }
                    handleBulkLockToggle('unlock');
                  }}
                  className="flex items-center justify-center gap-2 px-4 py-2 text-blue-600 transition-colors rounded-md bg-blue-50 hover:bg-blue-100"
                >
                  <Unlock size={16} />
                  {searchResource || searchDate ? '필터 항목 해제' : '전체 해제'}
                </button>
                <button
                  onClick={() => {
                    if (!searchResource && !searchDate) {
                      if (!window.confirm('주의: 필터링하지 않은 전체 잠금은 모든 날짜, 모든 시간대의 예약이 취소될 수 있으며 이 작업은 되돌릴 수 없습니다. 계속하시겠습니까?')) {
                        return;
                      }
                    }
                    handleBulkLockToggle('lock');
                  }}
                  className="flex items-center justify-center gap-2 px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  <Lock size={16} />
                  {searchResource || searchDate ? '필터 항목 잠금' : '전체 잠금'}
                </button>
                <button
                  onClick={() => {
                    if (!searchResource && !searchDate) {
                      if (!window.confirm('주의: 필터링하지 않은 전체 삭제는 모든 날짜, 모든 시간대의 예약이 취소되며 이 작업은 되돌릴 수 없습니다. 계속하시겠습니까?')) {
                        return;
                      }
                    }
                    handleDeleteFilteredSlots();
                  }}
                  className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  <Trash2 size={16} />
                  {searchResource || searchDate ? '필터 항목 삭제' : '전체 삭제'}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-8">
          {Object.keys(groupedByDate).length > 0 ? (
            Object.entries(groupedByDate).map(([date, resources]) => (
              <div key={date} className="p-4 border rounded-lg">
                <h4 className="pb-2 mb-4 text-lg font-medium border-b">{date}</h4>
                <div className="space-y-6">
                  {Object.entries(resources).map(([resourceName, data]) => (
                    <div key={`${date}_${resourceName}`} className="p-4 ml-4 rounded-lg bg-gray-50">
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center gap-4">
                          <h5 className="font-semibold text-md">
                            {type === 'tour' 
                              ? resourceName 
                              : `${resourceName} (${data.floor}층 ${data.size}인실)`}
                          </h5>
                          <button
                            onClick={() => {
                              const isAllSelected = data.slots.every(slot => 
                                selectedSlots.includes(slot.id)
                              );
                              handleSelectAll(data.slots, !isAllSelected);
                            }}
                            className="px-2 py-1 text-sm text-blue-600 border border-blue-600 rounded hover:bg-blue-50"
                          >
                            {data.slots.every(slot => selectedSlots.includes(slot.id))
                              ? '전체 해제'
                              : '전체 선택'}
                          </button>
                          <span className="text-sm text-gray-500">
                            {data.slots.filter(slot => selectedSlots.includes(slot.id)).length}개 선택됨
                          </span>
                        </div>
                        <button
                          onClick={() => handleDeleteResourceSlots(data.resourceId, date)}
                          className="text-sm text-red-600 hover:text-red-800"
                        >
                          전체 삭제
                        </button>
                      </div>
                      <div className="grid grid-cols-6 gap-2">
                        {data.slots.map((slot) => (
                          <div
                            key={slot.id}
                            className="relative group"
                            onClick={() => handleSlotSelection(slot.id)}
                          >
                            <div className={`
                              px-3 py-2 text-sm text-center rounded shadow-sm cursor-pointer
                              ${slot.is_locked 
                                ? 'bg-gray-200' // 잠긴 상태
                                : !slot.available 
                                  ? 'bg-sky-50' // 예약된 상태 (하늘색)
                                  : 'bg-white hover:bg-gray-50' // 일반 상태
                              }
                              ${selectedSlots.includes(slot.id) ? 'ring-2 ring-blue-500' : ''}
                            `}>
                              {slot.is_locked && (
                                <div className="absolute top-1 left-1">
                                  <Lock size={14} className="text-red-500" />
                                </div>
                              )}
                              <div className={`mt-1 ${slot.is_locked && 'ml-5'}`}>
                                {slot.time}
                                {type === 'tour' && (
                                  <div className="text-xs text-gray-500">
                                    ({slot.duration}분)
                                  </div>
                                )}
                              </div>
                            </div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteSlot(slot.id);
                              }}
                              className="absolute top-0 right-0 hidden p-1 -mt-2 -mr-2 text-xs text-red-600 bg-white rounded-full shadow-sm group-hover:block hover:text-red-800"
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="py-4 text-center text-gray-500">
              {searchResource || searchDate ? '검색 결과가 없습니다.' : '설정된 가용 시간이 없습니다.'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}