import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import api from '../../../api/axios';
import { useEffect, useState } from 'react';
import { Menu } from 'lucide-react';
import logoImage from '../../../assets/logo.png';

export default function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await api.get('/api/admin/tours/');
        if (response.status < 200 || response.status >= 300) {
          navigate('/admin');
        }
      } catch (error) {
        navigate('/admin');
      }
    };

    checkSession();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await api.post('/api/admin/logout/');
      navigate('/admin');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const menuItems = [
    { path: '/admin/dashboard', label: '대시보드' },
    { path: '/admin/schedule', label: '스케줄' },
    { path: '/admin/reservation', label: '예약 관리' },
    { path: '/admin/users', label: '회원 관리' },
    { path: '/admin/settings', label: '설정' },
  ];

  return (
    <header className="bg-white shadow">
      <div className="max-w-[99%] mx-auto px-4 py-4">
        <div className="flex items-center">
          <div className="flex items-center">
            <img 
              src={logoImage} 
              alt='logo' 
              className='w-auto h-12 mr-4'
            />
            
            {/* 데스크톱 네비게이션 */}
            <nav className="hidden space-x-4 md:flex">
              {menuItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === item.path
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-500 hover:text-gray-900'
                  }`}
                >
                  {item.label}
                </button>
              ))}
            </nav>
          </div>
  
          <div className="flex items-center ml-auto"> {/* margin-left: auto로 오른쪽 정렬 */}
            {/* 모바일 메뉴 버튼 */}
            <button
              className="p-2 md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="w-6 h-6" />
            </button>
  
            {/* 로그아웃 버튼 - 데스크톱 */}
            <button
              onClick={handleLogout}
              className="hidden px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700 md:block"
            >
              로그아웃
            </button>
          </div>
        </div>
  
        {/* 모바일 네비게이션 */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="pt-2 pb-3 space-y-1">
            {menuItems.map((item) => (
              <button
                key={item.path}
                onClick={() => {
                  navigate(item.path);
                  setIsMenuOpen(false);
                }}
                className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium ${
                  location.pathname === item.path
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                {item.label}
              </button>
            ))}
            <button
              onClick={handleLogout}
              className="w-full px-4 py-2 mt-2 text-white bg-red-600 rounded hover:bg-red-700"
            >
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}