import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Users, Building2, X } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import ReservationModal from "../../components/user/ReservationModal";
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

const TimeSelectionAlert = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50" onClick={onClose} />
      <div className="relative z-50 w-[320px] rounded-xl bg-white p-6 shadow-lg">
        <button 
          onClick={onClose}
          className="absolute p-1 rounded-lg right-4 top-4 hover:bg-gray-100"
        >
          <X className="w-4 h-4 text-gray-500" />
        </button>
        <h3 className="mb-2 text-lg font-semibold">알림</h3>
        <p className="mb-6 text-gray-600">{message}</p>
        <button
          onClick={onClose}
          className="w-full py-2.5 text-white bg-sky-700 hover:bg-sky-800 rounded-lg transition-colors"
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default function DashboardPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDocent, setSelectedDocent] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [scheduleData, setScheduleData] = useState({
    tour_schedule: [],
    room_schedule: []
  });
  const [tourTimeSlots, setTourTimeSlots] = useState([]);
  const [roomTimeSlots, setRoomTimeSlots] = useState([]);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [selectedTourTime, setSelectedTourTime] = useState(null);
  const [selectedDashboardTimeSlots, setSelectedDashboardTimeSlots] = useState([]);
  const [showTimeAlert, setShowTimeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [reservations, setReservations] = useState({ tours: [], meetings: [] });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const ROOM_TIME_INTERVAL = 10;

  const calculateTimeRange = (timeSlots) => {
    if (!timeSlots.length) return '';
    
    // 시간대 정렬
    const sortedSlots = [...timeSlots].sort((a, b) => {
      const [aHours, aMinutes] = a.time.split(':').map(Number);
      const [bHours, bMinutes] = b.time.split(':').map(Number);
      return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
    });
  
    // 시작 시간
    const startTime = formatTime(sortedSlots[0].time);
    
    // 단일 시간대 선택인 경우
    if (timeSlots.length === 1) {
      const [hours, minutes] = sortedSlots[0].time.split(':').map(Number);
      const endTotalMinutes = hours * 60 + minutes + ROOM_TIME_INTERVAL;
      const endHours = Math.floor(endTotalMinutes / 60);
      const endMinutes = endTotalMinutes % 60;
      
      const endTime = formatTime(
        `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`
      );
      return `${startTime} - ${endTime}`;
    }
  
    // 여러 시간대 선택인 경우
    const [lastHours, lastMinutes] = sortedSlots[sortedSlots.length - 1].time.split(':').map(Number);
    const endTotalMinutes = lastHours * 60 + lastMinutes + ROOM_TIME_INTERVAL;
    const endHours = Math.floor(endTotalMinutes / 60);
    const endMinutes = endTotalMinutes % 60;
    
    const endTime = formatTime(
      `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`
    );
    
    return `${startTime} - ${endTime}`;
  };

  const getTimeInterval = (timeSlots) => {
    if (timeSlots.length < 2) return 0;
    
    // 첫 두 시간대의 차이를 계산하여 interval 동적 할당
    const slots = timeSlots.slice(0, 2);
    const [time1, time2] = slots.map(slot => {
      const [hours, minutes] = slot.time.split(':').map(Number);
      return hours * 60 + minutes;
    });
      
    return time2 - time1;
  };
  
  const calculateTotalMinutes = (timeSlots) => {
    if (!timeSlots.length) return 0;
    
    // 시간대 정렬
    const sortedSlots = [...timeSlots].sort((a, b) => {
      const [aHours, aMinutes] = a.time.split(':').map(Number);
      const [bHours, bMinutes] = b.time.split(':').map(Number);
      return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
    });
  
    // 단일 시간대 선택인 경우
    if (timeSlots.length === 1) {
      return ROOM_TIME_INTERVAL;
    }
  
    // 여러 시간대 선택인 경우
    const [firstHours, firstMinutes] = sortedSlots[0].time.split(':').map(Number);
    const [lastHours, lastMinutes] = sortedSlots[sortedSlots.length - 1].time.split(':').map(Number);
    
    const firstTime = firstHours * 60 + firstMinutes;
    const lastTime = lastHours * 60 + lastMinutes;
    
    return (lastTime - firstTime) + ROOM_TIME_INTERVAL;
  };

  useEffect(() => {
    const fetchEventDates = async () => {
      try {
        const response = await api.get('/api/user/event-dates/');
        setDates(prevDates => {
          if (JSON.stringify(prevDates) === JSON.stringify(response.data)) {
            return prevDates;
          }
          if (response.data.length > 0 && !selectedDate) {
            setSelectedDate(response.data[0].date);
          }
          return response.data;
        });
      } catch (error) {
        toast.error('행사 일정을 불러오는데 실패했습니다.');
      }
    };
    
    fetchEventDates();
    const intervalId = setInterval(fetchEventDates, 30000);
    return () => clearInterval(intervalId);
  }, [selectedDate]);

  const fetchSchedule = useCallback(async () => {
    if (!selectedDate) return;
    try {
      const response = await api.get(`/api/user/schedule/?date=${selectedDate}`);
      setScheduleData(prevData => {
        if (JSON.stringify(prevData) === JSON.stringify(response.data)) {
          return prevData;
        }
        return response.data;
      });
    } catch (error) {
      if (error.response?.status === 404) {
        setScheduleData({ tour_schedule: [], room_schedule: [] });
        setTourTimeSlots([]);
        setRoomTimeSlots([]);
      } else {
        toast.error('예약 현황을 불러오는데 실패했습니다.');
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedDate) return;

    fetchSchedule();
    const intervalId = setInterval(fetchSchedule, 30000);
    return () => clearInterval(intervalId);
  }, [selectedDate, fetchSchedule]);

  const fetchReservations = useCallback(async () => {
    try {
      const response = await api.get('/api/user/my-reservations/');
      setReservations(prevReservations => {
        const newReservations = {
          tours: response.data.tours.filter(tour => tour.status === 'APPROVED'),
          meetings: response.data.meetings.filter(meeting => 
            meeting.status === 'APPROVED' || meeting.status === 'COMPLETED'
          )
        };
        
        if (JSON.stringify(prevReservations) === JSON.stringify(newReservations)) {
          return prevReservations;
        }
        return newReservations;
      });
    } catch (error) {
      toast.error('예약 내역을 불러오는데 실패했습니다.');
    }
  }, [location.state]);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    fetchReservations();
    const intervalId = setInterval(fetchReservations, 30000);
    return () => clearInterval(intervalId);
  }, [fetchReservations]);

  useEffect(() => {
    if (!selectedDocent) {
      setTourTimeSlots(prevSlots => prevSlots.length ? [] : prevSlots);
      return;
    }
    
    if (scheduleData.tour_schedule) {
      const selectedSchedule = scheduleData.tour_schedule.find(
        schedule => schedule.docent.id === parseInt(selectedDocent)
      );
      
      setTourTimeSlots(prevSlots => {
        const newSlots = selectedSchedule?.time_slots?.map(slot => ({
          ...slot,
          duration: slot.duration
        })) || [];
        
        if (JSON.stringify(prevSlots) === JSON.stringify(newSlots)) {
          return prevSlots;
        }
        return newSlots;
      });
    }
  }, [selectedDocent, scheduleData]);

  useEffect(() => {
    if (!selectedRoom) {
      setRoomTimeSlots(prevSlots => prevSlots.length ? [] : prevSlots);
      return;
    }
  
    if (scheduleData.room_schedule) {
      const selectedSchedule = scheduleData.room_schedule.find(
        schedule => schedule.room.id === parseInt(selectedRoom)
      );
      
      setRoomTimeSlots(prevSlots => {
        const newSlots = selectedSchedule?.time_slots || [];
        if (JSON.stringify(prevSlots) === JSON.stringify(newSlots)) {
          return prevSlots;
        }
        return newSlots;
      });
    }
  }, [selectedRoom, scheduleData]);

  const isTimeSelectable = (date, time) => {
    const now = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const targetDateStr = `${date} ${time}`;
    return new Date(targetDateStr) > new Date(now);
  };

  const getStatusStyle = (slot) => {
    const isPastTime = !isTimeSelectable(selectedDate, slot.time);
    
    if (isPastTime) {
      return 'bg-gray-100 text-gray-400 line-through cursor-not-allowed';
    }
    if (slot.reservation) {
      return 'bg-sky-50 border-sky-200';
    }
    if (!slot.is_available) {
      return 'bg-gray-100 text-gray-500';
    }
    return 'hover:bg-gray-50 cursor-pointer';
  };

  const handleTourTimeClick = (slot, selectedSchedule) => {
    if (!slot.is_available || slot.reservation || !isTimeSelectable(selectedDate, slot.time)) {
      return;
    }
  
    setSelectedTourTime({
      ...slot,
      duration: slot.duration
    });
    
    setTourTimeSlots(prevSlots => 
      prevSlots.map(s => ({
        ...s,
        isSelected: s.time === slot.time
      }))
    );
  };

  const handleRoomTimeClick = (slot, selectedSchedule) => {
    if (!slot.is_available || slot.reservation || !isTimeSelectable(selectedDate, slot.time)) {
      return;
    }
  
    if (selectedDashboardTimeSlots.some(s => s.time === slot.time)) {
      const slotIndex = selectedDashboardTimeSlots.findIndex(s => s.time === slot.time);
      if (slotIndex > 0 && slotIndex < selectedDashboardTimeSlots.length - 1) {
        setAlertMessage("연속된 시간으로만 예약이 가능합니다. 처음이나 마지막 시간만 취소할 수 있습니다.");
        setShowTimeAlert(true);
        return;
      }
      const updatedSlots = selectedDashboardTimeSlots.filter(s => s.time !== slot.time);
      setSelectedDashboardTimeSlots(updatedSlots);
      return;
    }
  
    if (selectedDashboardTimeSlots.length === 0) {
      setSelectedDashboardTimeSlots([slot]);
      return;
    }
  
    const allSlots = roomTimeSlots;
    const firstSelectedTime = selectedDashboardTimeSlots[0].time;
    const currentTime = slot.time;
    
    const startIndex = allSlots.findIndex(s => s.time === firstSelectedTime);
    const endIndex = allSlots.findIndex(s => s.time === currentTime);
    
    const start = Math.min(startIndex, endIndex);
    const end = Math.max(startIndex, endIndex);
    
    const newSelectedSlots = allSlots.slice(start, end + 1).filter(s => 
      s.is_available && 
      !s.reservation && 
      isTimeSelectable(selectedDate, s.time)
    );
  
    if (newSelectedSlots.length === (end - start + 1)) {
      setSelectedDashboardTimeSlots(newSelectedSlots);
    } else {
      setAlertMessage("선택한 범위 내에 예약할 수 없는 시간이 포함되어 있습니다.");
      setShowTimeAlert(true);
    }
  };

  useEffect(() => {
    setSelectedTourTime(null);
  }, [selectedDocent, selectedDate]);

  const handleViewAllReservations = () => {
    setShowReservationModal(false);
    navigate('/reservations');
  };

  const handleTourReservation = () => {
    if (!selectedDocent) {
      setAlertMessage("도슨트를 선택해주세요.");
      setShowTimeAlert(true);
      return;
    }

    if (!selectedTourTime) {
      setAlertMessage("예약하실 시간을 선택해주세요.");
      setShowTimeAlert(true);
      return;
    }

    const selectedSchedule = scheduleData.tour_schedule.find(
      schedule => schedule.docent.id === parseInt(selectedDocent)
    );

    navigate('/reserve-tour', {
      state: {
        date: selectedDate,
        docentId: selectedDocent,
        time: selectedTourTime.time,
        docentName: selectedSchedule?.docent.name,
        docentLanguage: selectedSchedule?.docent.language,
        skipTimeSelection: true
      }
    });
  };

  const handleRoomReservation = () => {
    if (!selectedRoom) {
      setAlertMessage("미팅룸을 선택해주세요.");
      setShowTimeAlert(true);
      return;
    }

    if (selectedDashboardTimeSlots.length === 0) {
      setAlertMessage("예약하실 시간을 선택해주세요.");
      setShowTimeAlert(true);
      return;
    }

    const selectedSchedule = scheduleData.room_schedule.find(
      schedule => schedule.room.id === parseInt(selectedRoom)
    );
    
    navigate('/reserve-room', {
      state: {
        date: selectedDate,
        roomId: selectedRoom,
        selectedTimes: selectedDashboardTimeSlots,
        roomName: selectedSchedule?.room.name,
        roomSize: selectedSchedule?.room.size,
        skipTimeSelection: true
      }
    });
  };

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      <header className="mb-6">
        <h1 className="text-2xl font-bold text-sky-700">CES 2025 예약</h1>
        <p className="font-bold text-gray-600">실시간 예약 현황</p>
      </header>

      <Card className="mb-6">
        <CardContent className="pt-6">
          <select
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value);
              setSelectedDocent("");
              setSelectedRoom("");
              setTourTimeSlots([]);
              setRoomTimeSlots([]);
              setSelectedTourTime(null);
              setSelectedDashboardTimeSlots([]);
            }}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-sky-500"
          >
            {dates.map((date) => (
              <option key={date.id} value={date.date}>
                {date.date}
              </option>
            ))}
          </select>
        </CardContent>
      </Card>

      <Tabs defaultValue="tours" className="w-full" onValueChange={() => {
        setSelectedDocent("");
        setSelectedRoom("");
        setTourTimeSlots([]);
        setRoomTimeSlots([]);
        setSelectedTourTime(null);
        setSelectedDashboardTimeSlots([]);
      }}>
        <TabsList className="grid w-full grid-cols-2 h-12 bg-gray-100/80 p-1.5 rounded-xl">
          <TabsTrigger 
            value="tours"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Users className="w-4 h-4" />
              <span className="font-medium">VIP 투어</span>
            </div>
          </TabsTrigger>
          <TabsTrigger 
            value="rooms"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Building2 className="w-4 h-4" />
              <span className="font-medium">미팅룸</span>
            </div>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tours">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Users className="w-5 h-5" />
                VIP 투어 예약 현황
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedDocent}
                onChange={(e) => {
                  setSelectedDocent(e.target.value);
                  setSelectedTourTime(null);
                }}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">도슨트를 선택하세요</option>
                {scheduleData.tour_schedule?.map((schedule) => (
                  <option key={schedule.docent.id} value={schedule.docent.id}>
                    {schedule.docent.name} ({schedule.docent.language})
                  </option>
                ))}
              </select>

              {tourTimeSlots.length > 0 ? (
                <div className="grid grid-cols-4 gap-1">
                  {tourTimeSlots.map((slot) => {
                    const isPastTime = !isTimeSelectable(selectedDate, slot.time);
                    const selectedSchedule = scheduleData.tour_schedule.find(
                      schedule => schedule.docent.id === parseInt(selectedDocent)
                    );
                    const isSelected = selectedTourTime?.time === slot.time;
                    return (
                      <div
                        key={`${selectedDocent}-${slot.time}`}
                        className={`p-2 border rounded-lg ${getStatusStyle(slot)} ${
                          isSelected ? 'border-sky-500 bg-sky-50' : ''
                        }`}
                        onClick={() => handleTourTimeClick(slot, selectedSchedule)}
                      >
                        <div className="text-center">
                          <div className="text-xs font-medium">
                            {slot.time ? formatTime(slot.time) : ''}
                          </div>
                          {/* duration 표시 추가 */}
                          {!isPastTime && !slot.reservation && slot.is_available && (
                            <div className="text-[10px] text-gray-500">
                              ({slot.duration}분)
                            </div>
                          )}
                          {isPastTime ? (
                            <div className="text-xs text-gray-400">
                              지난 시간
                            </div>
                          ) : slot.reservation ? (
                            <>
                              <div className="text-xs text-gray-500 truncate">
                                예약 마감
                              </div>
                            </>
                          ) : slot.is_available ? (
                            <div className="text-xs truncate text-sky-600">
                              가능
                            </div>
                          ) : (
                            <div className="text-xs text-gray-500 truncate">
                              불가
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="py-8 text-center text-gray-500">
                  도슨트를 선택하면 <br></br>예약 현황이 표시됩니다.
                </div>
              )}

              {selectedTourTime && (
                <div className="p-3 mt-4 border rounded-lg bg-sky-50 border-sky-100">
                  <div className="text-sm font-medium text-sky-900">선택된 시간</div>
                  <div className="mt-1 text-sm text-sky-700">
                    {formatTime(selectedTourTime.time)}
                    <div className="text-xs text-sky-600">
                      총 {selectedTourTime.duration}분
                    </div>
                  </div>
                </div>
              )}
              <div className="py-3 mt-4 text-center text-gray-500">*투어시간은 약 25분 소요 예정입니다.</div>
              <button
                onClick={handleTourReservation}
                className="w-full py-3 mt-6 mb-4 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
              >
                {selectedTourTime ? "선택한 시간으로 예약하기" : "투어 예약 신청하기"}
              </button>
              <div className="py-1 text-center text-gray-500">*문의 : choah1@sk.com</div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="rooms">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Building2 className="w-5 h-5" />
                미팅룸 예약 현황
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedRoom}
                onChange={(e) => {
                  setSelectedRoom(e.target.value);
                  setSelectedDashboardTimeSlots([]);
                }}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">미팅룸을 선택하세요</option>
                {scheduleData.room_schedule
                  ?.sort((a, b) => {
                    // 숫자로 시작하는 회의실과 문자로 시작하는 회의실을 분리하여 정렬
                    const aIsNumber = /^\d/.test(a.room.name);
                    const bIsNumber = /^\d/.test(b.room.name);
                    
                    if (aIsNumber && !bIsNumber) return 1;
                    if (!aIsNumber && bIsNumber) return -1;
                    
                    return a.room.name.localeCompare(b.room.name, undefined, {numeric: true});
                  })
                  .map((schedule) => (
                    <option key={schedule.room.id} value={schedule.room.id}>
                      {schedule.room.name} ({schedule.room.floor}층 {schedule.room.size}인실)
                      {schedule.room.is_vvip ? ' [VVIP 전용]' : 
                      schedule.room.is_vip ? ' [VIP 전용]' : ''}
                    </option>
                  ))}
              </select>

              {/* 선택된 미팅룸의 등급 표시 */}
              {selectedRoom && scheduleData.room_schedule && (
                <div className="mb-3">
                  {(() => {
                    const selectedRoomData = scheduleData.room_schedule.find(
                      schedule => schedule.room.id === parseInt(selectedRoom)
                    );
                    if (selectedRoomData?.room.is_vvip) {
                      return (
                        <div className="px-3 py-2 mb-3 text-sm text-purple-700 border border-purple-200 rounded-lg bg-purple-50">
                          VVIP 전용 미팅룸입니다.
                        </div>
                      );
                    } else if (selectedRoomData?.room.is_vip) {
                      return (
                        <div className="px-3 py-2 mb-3 text-sm text-yellow-700 border border-yellow-200 rounded-lg bg-yellow-50">
                          VIP 전용 미팅룸입니다.
                        </div>
                      );
                    }
                    return null;
                  })()}
                </div>
              )}

              {roomTimeSlots.length > 0 && (
                <div className="mb-3 text-sm text-gray-600">
                  원하시는 회의 시간만큼 선택해 주세요.
                  <br />
                  <span className="text-xs text-gray-500">
                    (시작 시간과 종료 시간을 클릭하여 연속된 시간대를 선택할 수 있습니다.)
                  </span>
                </div>
              )}

              {roomTimeSlots.length > 0 ? (
                <>
                  <div className="grid grid-cols-4 gap-1">
                    {roomTimeSlots.map((slot) => {
                      const isPastTime = !isTimeSelectable(selectedDate, slot.time);
                      const selectedSchedule = scheduleData.room_schedule.find(
                        schedule => schedule.room.id === parseInt(selectedRoom)
                      );
                      const isSelected = selectedDashboardTimeSlots.some(s => s.time === slot.time);
                      return (
                        <div
                          key={`${selectedRoom}-${slot.time}`}
                          className={`p-2 border rounded-lg ${getStatusStyle(slot)} ${
                            isSelected ? 'border-sky-500 bg-sky-50' : ''
                          }`}
                          onClick={() => handleRoomTimeClick(slot, selectedSchedule)}
                        >
                          <div className="text-center">
                            <div className="text-xs font-medium">
                              {slot.time ? formatTime(slot.time) : ''}
                            </div>
                            {isPastTime ? (
                              <div className="text-xs text-gray-400">
                                지난 시간
                              </div>
                            ) : slot.reservation ? (
                              <>
                                <div className="text-xs text-gray-500 truncate">
                                  예약 마감
                                </div>
                              </>
                            ) : slot.is_available ? (
                              <div className="text-xs truncate text-sky-600">
                                가능
                              </div>
                            ) : (
                              <div className="text-xs text-gray-500 truncate">
                                불가
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {selectedDashboardTimeSlots.length > 0 && (
                    <div className="p-3 mt-4 border rounded-lg bg-sky-50 border-sky-100">
                      <div className="text-sm font-medium text-sky-900">선택된 시간</div>
                      <div className="mt-1 text-sm text-sky-700">
                        {calculateTimeRange(selectedDashboardTimeSlots, getTimeInterval(roomTimeSlots))}
                        <div className="mt-1 text-xs text-sky-600">
                          총 {calculateTotalMinutes(selectedDashboardTimeSlots)}분
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="py-8 text-center text-gray-500">
                  미팅룸을 선택하면 <br></br>예약 현황이 표시됩니다.
                </div>
              )}

              <button
                onClick={handleRoomReservation}
                className="w-full py-3 mt-6 mb-4 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
              >
                {selectedDashboardTimeSlots.length > 0 ? "선택한 시간으로 예약하기" : "미팅룸 예약 신청하기"}
              </button>
              <div className="py-1 text-center text-gray-500">*문의 : choah1@sk.com</div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <ReservationModal 
        open={showReservationModal}
        onOpenChange={setShowReservationModal}
        reservations={reservations}
        onViewAll={handleViewAllReservations}
      />

      <TimeSelectionAlert 
        isOpen={showTimeAlert} 
        onClose={() => setShowTimeAlert(false)}
        message={alertMessage}
      />
    </div>
  );
}