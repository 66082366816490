import { useState, useEffect } from 'react';
import { Card } from '../../ui/card';
import { Calendar, Users } from 'lucide-react';
import { toast } from 'react-hot-toast';
import api from '../../../api/axios';
import TimeTableDetailModal from './TimeTableDetailModal';

const START_HOUR = 9;
const END_HOUR = 18;
const HOURS_IN_DAY = END_HOUR - START_HOUR;
const TIME_SLOT_WIDTH = 300;
const ITEM_HEIGHT = 100;

const ScrollableContainer = ({ children }) => {
  return (
    <div 
      className="relative overflow-x-auto"
      style={{
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'thin',
        scrollbarColor: '#E2E8F0 transparent'
      }}
    >
      <style jsx global>{`
        /* Webkit (Chrome, Safari, Edge) 스크롤바 스타일 */
        .overflow-x-auto::-webkit-scrollbar {
          height: 4px;
        }
        
        .overflow-x-auto::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .overflow-x-auto::-webkit-scrollbar-thumb {
          background-color: #E5E7EB;
          border-radius: 20px;
          border: 2px solid transparent;
        }

        .overflow-x-auto::-webkit-scrollbar-thumb:hover {
          background-color: #D1D5DB;
        }

        /* Firefox 스크롤바 스타일 */
        .overflow-x-auto {
          scrollbar-width: thin;
          scrollbar-color: #E5E7EB transparent;
        }
      `}</style>
      {children}
    </div>
  );
};

const parseTime = (timeString) => {
  if (!timeString) return 0;
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
};

const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

// ReservationCard Component
const ReservationCard = ({ reservation, type, width, intervalMinutes, onClick }) => {
  const COMPACT_THRESHOLD = 100;
  const MEDIUM_THRESHOLD = 150;
  
  const isCompact = width < COMPACT_THRESHOLD;
  const isMedium = width < MEDIUM_THRESHOLD && !isCompact;

  const startTime = type === 'tour'
    ? reservation.available_tour?.time
    : reservation.time_slots?.[0]?.start_time;

  const endTime = type === 'tour'
    ? formatTime(parseTime(startTime) + reservation.available_tour.duration)
    : reservation.time_slots?.[reservation.time_slots.length - 1]?.end_time;

  const timeRange = `${startTime} - ${endTime}`;

  const getBgColor = () => {
    if (type === 'tour') return 'bg-sky-50 border-sky-200 hover:bg-sky-100';
    return 'bg-emerald-50 border-emerald-200 hover:bg-emerald-100';
  };

  const getTextColor = () => {
    if (type === 'tour') return 'text-sky-900';
    return 'text-emerald-900';
  };

  const startTimeMinutes = parseTime(startTime);
  const endTimeMinutes = parseTime(endTime);
  const duration = endTimeMinutes - startTimeMinutes;

  return (
    <button
      onClick={onClick}
      className={`absolute px-2 py-1.5 transition-all border rounded-lg shadow-sm cursor-pointer overflow-hidden ${getBgColor()}`}
      style={{
        left: `${((parseTime(startTime) - parseTime(`${START_HOUR}:00`)) * TIME_SLOT_WIDTH) / 60}px`,
        width: `${(duration * TIME_SLOT_WIDTH) / 60}px`,
        top: '8px',
        height: `${ITEM_HEIGHT - 16}px`
      }}
    >
      {isCompact ? (
        <div className="flex flex-col items-center justify-center h-full">
          <div className={`font-medium ${getTextColor()} text-center`}
              style={{ fontSize: '9px' }}>
            {reservation.visitor.name}
          </div>
        </div>
      ) : isMedium ? (
        <div className="flex flex-col h-full">
          <div className={`text-xs font-medium ${getTextColor()}`}>
            {timeRange}
          </div>
          <div className={`mt-0.5 text-xs truncate ${getTextColor()}`}>
            {type === 'tour' ? reservation.visitor.company : reservation.visitor.title}
          </div>
          <div className="mt-0.5 text-xs text-gray-600">
            {reservation.visitor.name}({reservation.visitor.count}명) / {reservation.user.name}
          </div>
        </div>
      ) : (
        <>
          <div className={`text-sm font-medium ${getTextColor()}`}>
            {timeRange}
          </div>
          <div className={`mt-0.5 text-sm truncate ${getTextColor()}`}>
            {type === 'tour' ? reservation.visitor.company : reservation.visitor.title}
          </div>
          <div className="mt-0.5 text-xs text-gray-700">
            {reservation.visitor.name}({reservation.visitor.count}명) / {reservation.user.name}
          </div>
        </>
      )}
    </button>
  );
};

// TimetableGrid Component
const TimetableGrid = ({
  type,
  items,
  selectedDate,
  reservations = [],
  onShowDetail,
  getInterval
}) => {
  const [currentTimePosition, setCurrentTimePosition] = useState(null);

  const calculateCurrentTimePosition = () => {
    const now = new Date();
    // LA 시간으로 변환
    const laTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    const hours = laTime.getHours();
    const minutes = laTime.getMinutes();
    
    if (hours < START_HOUR || hours >= END_HOUR) return null;
    
    const totalMinutes = (hours - START_HOUR) * 60 + minutes;
    return (totalMinutes * TIME_SLOT_WIDTH) / 60;
  };

  useEffect(() => {
    const updatePosition = () => {
      setCurrentTimePosition(calculateCurrentTimePosition());
    };

    updatePosition();
    const interval = setInterval(updatePosition, 60000);
    return () => clearInterval(interval);
  }, []);

  const timeSlots = Array.from(
    { length: HOURS_IN_DAY },
    (_, i) => START_HOUR + i
  );

  const totalWidth = timeSlots.length * TIME_SLOT_WIDTH;

  return (
    <Card className="overflow-hidden">
      <ScrollableContainer>
        <div style={{ minWidth: `${totalWidth + 240}px` }}>
          {/* 시간축 헤더 */}
          <div className="flex border-b bg-gray-50">
            <div className="sticky left-0 z-20 p-4 border-r w-28 bg-gray-50">
              <div className="font-medium text-gray-900">
                {type === 'tour' ? '도슨트' : '미팅룸'}
              </div>
            </div>
            {timeSlots.map((hour, index) => (
              <div
                key={hour}
                className={`flex-none p-4 text-center ${index !== timeSlots.length - 1 ? 'border-r' : ''}`}
                style={{ width: TIME_SLOT_WIDTH }}
              >
                <div className="font-medium text-gray-900">
                  {`${hour.toString().padStart(2, '0')}:00`}
                </div>
              </div>
            ))}
            {currentTimePosition && selectedDate === new Date().toISOString().split('T')[0] && (
              <div 
                className="absolute z-30 px-2 py-1 text-xs font-medium text-white transform -translate-x-1/2 rounded-full bg-sky-600"
                style={{ 
                  left: `${currentTimePosition + 96}px`, 
                  top: '4px' 
                }}
              >
                {new Date().toLocaleString('en-US', { 
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                  timeZone: 'America/Los_Angeles'
                })}
              </div>
            )}
          </div>

          {/* 메인 그리드 */}
          <div className="relative">
            {items.map(item => (
              <div key={item.id} className="flex border-b last:border-b-0">
                <div className="sticky left-0 z-20 p-4 border-r w-28 bg-gray-50">
                  <div className="font-medium text-gray-900">{item.name}</div>
                  <div className="mt-1 text-sm text-gray-500">
                    {type === 'tour' ? item.language : `${item.floor}층 ${item.size}인실`}
                  </div>
                </div>

                <div className="relative flex flex-1" style={{ height: ITEM_HEIGHT }}>
                  {timeSlots.map((hour, index) => (
                    <div
                      key={hour}
                      className={`flex-none ${index !== timeSlots.length - 1 ? 'border-r' : ''}`}
                      style={{ width: TIME_SLOT_WIDTH }}
                    />
                  ))}

                  {reservations
                    .filter(res => {
                      if (!res) return false;
                      return type === 'tour'
                        ? res.available_tour?.docent?.id === item.id
                        : res.room?.id === item.id;
                    })
                    .map(reservation => {
                      const startTime = type === 'tour'
                        ? reservation.available_tour?.time
                        : reservation.time_slots?.[0]?.start_time;

                      if (!startTime) return null;

                      const [hours] = startTime.split(':').map(Number);
                      if (hours < START_HOUR || hours >= END_HOUR) return null;

                      const intervalMinutes = type === 'tour'
                        ? reservation.available_tour.duration
                        : reservation.total_duration;

                      const width = (intervalMinutes * TIME_SLOT_WIDTH) / 60;

                      return (
                        <ReservationCard
                          key={reservation.id}
                          reservation={reservation}
                          type={type}
                          width={width}
                          intervalMinutes={intervalMinutes}
                          onClick={() => onShowDetail({
                            ...reservation,
                            startTime,
                            endTime: type === 'tour'
                              ? formatTime(parseTime(startTime) + intervalMinutes)
                              : reservation.time_slots?.[reservation.time_slots.length - 1]?.end_time,
                            type
                          })}
                        />
                      );
                    })}

                  {currentTimePosition && selectedDate === new Date().toISOString().split('T')[0] && (
                    <div 
                      className="absolute top-0 bottom-0 z-30 w-0.5 bg-sky-600"
                      style={{ left: `${currentTimePosition}px` }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollableContainer>
    </Card>
  );
};

// Main Timetable Component
export default function Timetable({
  viewMode,
  setViewMode,
  dates = [],
  docents = [],
  rooms = [],
  approvedReservations = {},
  completedReservations = {},
  refreshData,
  selectedDate
}) {
  const [selectedReservation, setSelectedReservation] = useState(null);

  const mergeReservations = (date) => {
    const approved = approvedReservations[date] || { tours: [], meetings: [] };
    const completed = completedReservations[date] || { tours: [], meetings: [] };
    return {
      tours: [...approved.tours, ...completed.tours],
      meetings: [...approved.meetings, ...completed.meetings]
    };
  };

  const handleCancel = async (reservation, reason) => {
    try {
      await api.post(`/api/admin/reservations/${reservation.id}/action/`, {
        type: reservation.type === 'tour' ? 'VIP_TOUR' : 'GROUP_MEETING',
        action: 'cancel',
        reason
      });
      toast.success('예약이 취소되었습니다.');
      refreshData();
    } catch (error) {
      toast.error('예약 취소 중 오류가 발생했습니다.');
    }
  };

  if (!dates.length) {
    return (
      <Card className="p-8 text-center text-gray-500">
        조회 가능한 날짜가 없습니다.
      </Card>
    );
  }

  const dateReservations = mergeReservations(selectedDate);

  return (
    <div>
      <Card className="p-1">
        <div className="flex divide-x divide-gray-200">
          {['all', 'tour', 'meeting'].map(mode => (
            <button
              key={mode}
              onClick={() => setViewMode(mode)}
              className={`px-4 py-1.5 text-sm font-medium transition-colors first:rounded-l-md last:rounded-r-md
                ${viewMode === mode
                  ? 'bg-sky-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              {mode === 'all' ? '전체' : mode === 'tour' ? 'VIP 투어' : '미팅룸'}
            </button>
          ))}
        </div>
      </Card>

      {(viewMode === 'all' || viewMode === 'tour') && (
        <div>
          <h2 className="flex items-center gap-2 m-4 text-base font-medium text-gray-900">
            <Users className="w-5 h-5 text-sky-600" />
            VIP 투어
          </h2>
          <TimetableGrid
            type="tour"
            items={docents}
            selectedDate={selectedDate}
            reservations={dateReservations.tours}
            onShowDetail={setSelectedReservation}
          />
        </div>
      )}

      {(viewMode === 'all' || viewMode === 'meeting') && (
        <div>
          <h2 className="flex items-center gap-2 m-4 text-base font-medium text-gray-900">
            <Calendar className="w-5 h-5 text-emerald-600" />
            미팅룸
          </h2>
          <TimetableGrid
            type="meeting"
            items={rooms}
            selectedDate={selectedDate}
            reservations={dateReservations.meetings}
            onShowDetail={setSelectedReservation}
            getInterval={(reservation) => reservation.total_duration}
          />
        </div>
      )}

      <TimeTableDetailModal
        isOpen={!!selectedReservation}
        onClose={() => setSelectedReservation(null)}
        reservation={selectedReservation}
        onCancel={handleCancel}
        refreshData={refreshData}
      />
    </div>
  );
}