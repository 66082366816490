import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, ChevronLeft, Info, X, MessageCircle, Users, Building2, AlertTriangle } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import CancelModal from '../../components/user/CancelModal';
import api from '../../api/axios';
import { formatDateTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

const ReservationStatus = ({ status, reservation, type }) => {
  // LA 시간 기준으로 현재 시간 가져오기
  const now = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
  
  // 이벤트 날짜와 시간 조합
  const eventDate = type === 'TOUR' 
    ? reservation.available_tour.event_date.date 
    : reservation.event_date.date;
  const eventTime = type === 'TOUR'
    ? reservation.available_tour.time
    : reservation.time_range.split(' ~ ')[0];
  
  // 종료 시간 계산
  const [hours, minutes] = eventTime.split(':').map(Number);
  const duration = type === 'TOUR' ? reservation.available_tour.duration : 0;
  const endTime = `${String(hours).padStart(2, '0')}:${String(minutes + duration).padStart(2, '0')}`;
  
  // 이벤트 종료 시간 문자열 (DB 시간 그대로 사용)
  const eventEndStr = `${eventDate} ${endTime}`;
  
  const isExpired = new Date(eventEndStr) < new Date(now);
  const displayStatus = (status === 'APPROVED' && isExpired) ? 'COMPLETED' : status;

  const styles = {
    PENDING: 'bg-yellow-500',
    APPROVED: 'bg-green-500',
    CANCELLED: 'bg-gray-500',
    COMPLETED: 'bg-blue-500',
    REJECTED: 'bg-red-500',
    CANCEL_REQUESTED: 'bg-orange-500'
  };

  const labels = {
    PENDING: '대기중',
    APPROVED: '예약됨',
    CANCELLED: '취소됨',
    COMPLETED: '종료됨',
    REJECTED: '거절됨',
    CANCEL_REQUESTED: '취소 요청'
  };

  return (
    <span className={`px-2 py-1 text-sm text-white rounded-full ${styles[displayStatus]}`}>
      {labels[displayStatus]}
    </span>
  );
};

const ReservationActions = ({ type, reservation, onCancelClick, onEndMeeting, onFeedbackClick }) => {
  // LA 시간 기준으로 현재 시간 가져오기
  const now = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
  
  // 이벤트 날짜와 시간 조합
  const eventDate = type === 'TOUR' 
    ? reservation.available_tour.event_date.date 
    : reservation.event_date.date;
  const eventTime = type === 'TOUR'
    ? reservation.available_tour.time
    : reservation.time_range.split(' ~ ')[0];
  
  // 종료 시간 계산
  const [hours, minutes] = eventTime.split(':').map(Number);
  const duration = type === 'TOUR' ? reservation.available_tour.duration : 0;
  const endTime = `${String(hours).padStart(2, '0')}:${String(minutes + duration).padStart(2, '0')}`;
  
  // 이벤트 시작/종료 시간 문자열 (DB 시간 그대로 사용)
  const eventStartStr = `${eventDate} ${eventTime}`;
  const eventEndStr = `${eventDate} ${endTime}`;
  
  const currentTime = new Date(now);
  const eventStart = new Date(eventStartStr);
  const eventEnd = new Date(eventEndStr);

  const isSameDay = currentTime.toDateString() === eventStart.toDateString();
  const isTimeValid = currentTime < eventEnd;
  const isActiveOrCompleted = reservation.status === 'APPROVED' || reservation.status === 'COMPLETED';
  const isExpired = currentTime > eventEnd;
  const isCompletedOrExpired = reservation.status === 'COMPLETED' || isExpired;

  return (
    <div className="flex gap-2">
      {reservation.status === 'APPROVED' && (
        <>
          {(!isSameDay || isTimeValid) && !isCompletedOrExpired && (
            <button
              onClick={() => onCancelClick(reservation.id, type === 'TOUR' ? 'VIP_TOUR' : 'MEETING_ROOM')}
              className="flex items-center px-3 py-2 text-sm text-red-600 border border-red-200 rounded-lg hover:bg-red-50"
            >
              <X className="w-4 h-4 mr-2" />
              예약 취소
            </button>
          )}
          {type === 'MEETING' && isSameDay && !isTimeValid && !isCompletedOrExpired && (
            <button
              onClick={() => onEndMeeting(reservation.id)}
              className="flex items-center px-3 py-2 text-sm text-yellow-600 border border-yellow-200 rounded-lg hover:bg-yellow-50"
            >
              <Clock className="w-4 h-4 mr-2" />
              조기 종료
            </button>
          )}
        </>
      )}
      {type === 'MEETING' && isActiveOrCompleted && (
        <button
          onClick={() => onFeedbackClick(reservation)}
          className="flex items-center px-3 py-2 text-sm border rounded-lg text-sky-600 border-sky-200 hover:bg-sky-50"
        >
          <MessageCircle className="w-4 h-4 mr-2" />
          {reservation.feedback ? '피드백 수정' : '피드백 작성'}
        </button>
      )}
    </div>
  );
};

const ReservationDetailDialog = ({ reservation, type }) => {
  const labels = {
    TOUR: {
      name: "현장 컨택 실무자명/직함",
      company: "방문자 대표명/직함",
      phone: "현장 컨택 실무자 휴대번호",
      count: "방문 인원",
      note: "상세 정보"
    },
    MEETING: {
      name: "방문자명",
      company: "회사명",
      phone: "연락처",
      count: "방문인원",
      note: "요청사항"
    }
  };

  const currentLabels = labels[type === 'TOUR' ? 'TOUR' : 'MEETING'];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="flex items-center px-3 py-2 text-sm border rounded-lg hover:bg-gray-50">
          <Info className="w-4 h-4 mr-2" />
          상세 정보
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>예약 상세 정보</DialogTitle>
        </DialogHeader>
        <div className="mt-4 space-y-4">
          <div>
            <div className="text-sm text-gray-600">{currentLabels.name}</div>
            <div>{reservation.visitor.name}</div>
          </div>
          {type === 'MEETING' && (
            <div>
              <div className="text-sm text-gray-600">미팅 목적</div>
              <div>{reservation.visitor.title}</div>
            </div>
          )}
          <div>
            <div className="text-sm text-gray-600">{currentLabels.company}</div>
            <div>{reservation.visitor.company}</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">{currentLabels.phone}</div>
            <div>{reservation.visitor.phone}</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">{currentLabels.count}</div>
            <div>{reservation.visitor.count}명</div>
          </div>
          {reservation.note && (
            <div>
              <div className="text-sm text-gray-600">{currentLabels.note}</div>
              <div className="whitespace-pre-line">{reservation.note}</div>
            </div>
          )}
          {reservation.cancel_note && (
            <div>
              <div className="text-sm font-medium text-orange-600">취소 사유</div>
              <div className="p-3 mt-1 text-sm whitespace-pre-line border border-orange-200 rounded-lg bg-orange-50">
                {reservation.cancel_note}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default function MyReservationsPage() {
  const navigate = useNavigate();
  const [reservations, setReservations] = useState({ tours: [], meetings: [] });
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReservation, setCancelReservation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchReservations = useCallback(async () => {
    try {
      const response = await api.get('/api/user/my-reservations/');
      setReservations(prevReservations => {
        if (JSON.stringify(prevReservations) === JSON.stringify(response.data)) {
          return prevReservations;
        }
        return response.data;
      });
    } catch (error) {
      toast.error('예약 내역을 불러오는데 실패했습니다.');
    }
  }, []);

  // 초기 데이터 로딩 및 실시간 업데이트 설정
  useEffect(() => {
    fetchReservations().finally(() => {
      setIsInitialLoad(false);
    });
    const intervalId = setInterval(fetchReservations, 30000);
    return () => clearInterval(intervalId);
  }, [fetchReservations]);

  const handleEndMeeting = async (meetingId) => {
    try {
      await api.post(`/api/user/meeting-completed/${meetingId}/`);
      toast.success('미팅이 조기 종료되었습니다. \n 피드백을 작성해 주세요.');
      fetchReservations();
    } catch (error) {
      toast.error('미팅 종료에 실패했습니다.');
    }
  };

  const handleCancelClick = (id, type) => {
    setCancelReservation({ id, type });
    setShowCancelModal(true);
  };

  const handleCancelConfirm = async (cancelNote) => {
    setIsSubmitting(true);
    try {
      await api.post(`/api/user/cancel-reservation/${cancelReservation.id}/`, {
        reservation_type: cancelReservation.type,
        cancel_note: cancelNote
      });
      toast.success('취소 요청이 전송되었습니다. \n관리자 검토 후 이메일로 승인 여부를 안내드립니다.');
      setShowCancelModal(false);
      fetchReservations();
    } catch (error) {
      toast.error('취소 요청에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      toast.error('피드백 내용을 입력해주세요.');
      return;
    }
    
    try {
      await api.post(`/api/user/meeting-feedback/${selectedReservation.id}/`, {
        feedback
      });
      
      toast.success(selectedReservation.feedback 
        ? '피드백이 수정되었습니다.' 
        : '피드백이 제출되었습니다.'
      );
      
      setShowFeedbackDialog(false);
      setFeedback('');
      fetchReservations();
    } catch (error) {
      toast.error(selectedReservation.feedback 
        ? '피드백 수정에 실패했습니다.' 
        : '피드백 제출에 실패했습니다.'
      );
    }
  };

  // 초기 로딩 중일 때만 로딩 UI 표시
  if (isInitialLoad) {
    return (
      <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
        <div className="flex items-center justify-center h-64">
          <div className="w-8 h-8 border-b-2 rounded-full border-sky-600 animate-spin"></div>
        </div>
      </div>
    );
  }

  const renderReservationCard = (reservation, type) => (
    <Card key={reservation.id}>
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>
            {type === 'TOUR' 
              ? 'VIP 투어' 
              : reservation.room.name
            }
          </span>
          <div className="flex items-center gap-2">
            {reservation.status === 'CANCEL_REQUESTED' && (
              <span className="text-sm text-orange-600">
                <AlertTriangle className="w-4 h-4" />
              </span>
            )}
            <ReservationStatus 
              status={reservation.status} 
              reservation={reservation}
              type={type}
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="text-lg">
            {formatDateTime(
              type === 'TOUR'
                ? reservation.available_tour.event_date.date
                : reservation.event_date.date,
              type === 'TOUR'
                ? reservation.available_tour.time
                : reservation.time_range.split(' ~ ')[0],
              type === 'TOUR'
                ? (() => {
                    const [hours, minutes] = reservation.available_tour.time.split(':');
                    const endTime = new Date(reservation.available_tour.event_date.date);
                    endTime.setHours(parseInt(hours));
                    endTime.setMinutes(parseInt(minutes) + reservation.available_tour.duration);
                    return endTime.toTimeString().split(' ')[0];
                  })()
                : reservation.time_range.split(' ~ ')[1],
            )}
          </div>
          <div className="text-sm text-gray-600">
            {type === 'TOUR' 
              ? `도슨트: ${reservation.available_tour.docent.name} (${reservation.available_tour.docent.language}) - ${reservation.available_tour.duration}분`
              : `${reservation.room.floor}층 ${reservation.room.size}인실`
            }
          </div>
          <div className="flex items-center justify-between mt-4">
            <ReservationDetailDialog reservation={reservation} type={type} />
            <ReservationActions
              type={type}
              reservation={reservation}
              onCancelClick={handleCancelClick}
              onEndMeeting={handleEndMeeting}
              onFeedbackClick={(meeting) => {
                setSelectedReservation(meeting);
                setFeedback(meeting.feedback || '');
                setShowFeedbackDialog(true);
              }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      <header className="flex items-center mb-6">
        <button
          onClick={() => navigate(-1)}
          className="p-2 mr-2 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-bold">내 예약 내역</h1>
      </header>

      <Tabs defaultValue="tours" className="w-full">
        <TabsList className="grid w-full grid-cols-2 h-12 bg-gray-100/80 p-1.5 rounded-xl mb-6">
          <TabsTrigger 
            value="tours"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Users className="w-4 h-4" />
              <span className="font-medium">VIP 투어</span>
            </div>
          </TabsTrigger>
          <TabsTrigger 
            value="meetings"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Building2 className="w-4 h-4" />
              <span className="font-medium">미팅룸</span>
            </div>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tours">
          <div className="space-y-4">
            {reservations.tours.length > 0 ? (
              reservations.tours.map(tour => renderReservationCard(tour, 'TOUR'))
            ) : (
              <div className="py-8 text-center text-gray-500">
                예약된 투어가 없습니다
              </div>
            )}
          </div>
        </TabsContent>

        <TabsContent value="meetings">
          <div className="space-y-4">
            {reservations.meetings.length > 0 ? (
              reservations.meetings.map(meeting => renderReservationCard(meeting, 'MEETING'))
            ) : (
              <div className="py-8 text-center text-gray-500">
                예약된 미팅이 없습니다
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>

      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedReservation?.feedback ? '미팅 피드백 수정' : '미팅 피드백 작성'}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4">
            {selectedReservation?.feedback && (
              <div className="p-3 text-sm rounded-lg bg-gray-50">
                <div className="font-medium text-gray-600">기존 피드백</div>
                <div className="mt-1">{selectedReservation.feedback}</div>
              </div>
            )}
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder={
                selectedReservation?.feedback
                  ? '수정할 피드백을 작성해주세요.'
                  : '피드백을 작성해주세요.'
              }
              className="w-full h-32 p-3 border rounded-lg resize-none focus:ring-2 focus:ring-sky-500"
            />
            <div className="flex justify-end gap-2">
              <DialogClose asChild>
                <button className="px-4 py-2 text-sm border rounded-lg hover:bg-gray-50">
                  취소
                </button>
              </DialogClose>
              <button
               onClick={handleFeedbackSubmit}
               className="px-4 py-2 text-sm text-white rounded-lg bg-sky-600 hover:bg-sky-700"
             >
               {selectedReservation?.feedback ? '수정하기' : '제출하기'}
             </button>
           </div>
         </div>
       </DialogContent>
     </Dialog>

     <CancelModal 
      open={showCancelModal}
      onOpenChange={setShowCancelModal} 
      onConfirm={(cancelNote) => handleCancelConfirm(cancelNote)}
      reservationType={cancelReservation?.type}
      isSubmitting={isSubmitting}
      />
   </div>
 );
}