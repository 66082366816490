import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarDays, Clock, Users, Building2 } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import PageHeader from '../../components/user/PageHeader';
import StepProgress from '../../components/user/StepProgress';
import NavigationButtons from '../../components/user/NavigationButtons';
import VisitorForm from '../../components/user/VisitorForm';
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function RoomReservationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.state || {};

  const [step, setStep] = useState(() => {
    if (initialState.skipTimeSelection && initialState.date && initialState.time) {
        // 대시보드에서 직접 시간 선택한 경우 방문자 정보 단계로
        return 5;
    }
    if (initialState.date) return 2;
    return 1;
});

  const [dates, setDates] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialState.date || '');
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedHour, setSelectedHour] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visitorInfo, setVisitorInfo] = useState({
    title: '',
    name: '',
    company: '',
    phone: '',
    count: 1,
  });
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const [datesResponse, roomsResponse] = await Promise.all([
          api.get('/api/user/event-dates/'),
          api.get('/api/user/rooms/')
        ]);
  
        setDates(datesResponse.data);
        
        // rooms 데이터 구조 확인 및 처리
        if (Array.isArray(roomsResponse.data)) {
          setRooms(roomsResponse.data);
          
          if (initialState.roomId) {
            const room = roomsResponse.data.find(
              r => r.id === parseInt(initialState.roomId)
            );
            if (room) {
              setSelectedRoom(room);
              if (initialState.skipTimeSelection) {
                setStep(5);
              } else {
                setStep(3);
              }
            }
          }
        }
      } catch (error) {
        toast.error('데이터를 불러오는데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchInitialData();
  }, [initialState.roomId, initialState.skipTimeSelection]);

  useEffect(() => {
    if (initialState.skipTimeSelection && initialState.selectedTimes && allTimeSlots.length > 0) {
        // selectedTimes 배열을 그대로 사용
        const slots = initialState.selectedTimes.map(selectedTime => {
            const slot = allTimeSlots.find(t => t.time === selectedTime.time);
            return slot;
        }).filter(Boolean);
        
        if (slots.length > 0) {
            setSelectedTimeSlots(slots);
        }
    } else if (initialState.skipTimeSelection && initialState.time && allTimeSlots.length > 0) {
        // 기존 단일 시간 선택 로직 유지
        const slot = allTimeSlots.find(t => t.time === initialState.time);
        if (slot) {
            setSelectedTimeSlots([slot]);
        }
    }
  }, [allTimeSlots, initialState.skipTimeSelection, initialState.time, initialState.selectedTimes]);

  const isTimeSelectable = (date, time) => {
    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    const [hours, minutes] = time.split(':').map(Number);
    const targetDate = new Date(date);
    targetDate.setHours(hours, minutes, 0, 0);
  
    return targetDate > now;
  };

  const ROOM_TIME_INTERVAL = 10;

  const calculateTotalMinutes = (timeSlots) => {
    if (!timeSlots.length) return 0;
    if (timeSlots.length === 1) return ROOM_TIME_INTERVAL;
  
    // 시간대 정렬
    const sortedSlots = [...timeSlots].sort((a, b) => {
      const [aHours, aMinutes] = a.time.split(':').map(Number);
      const [bHours, bMinutes] = b.time.split(':').map(Number);
      return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
    });
  
    // 첫 번째와 마지막 선택된 시간을 분으로 변환
    const [firstHours, firstMinutes] = sortedSlots[0].time.split(':').map(Number);
    const [lastHours, lastMinutes] = sortedSlots[sortedSlots.length - 1].time.split(':').map(Number);
    
    const firstTimeInMinutes = firstHours * 60 + firstMinutes;
    const lastTimeInMinutes = lastHours * 60 + lastMinutes;
    
    // 마지막 선택 시간대의 duration을 위해 ROOM_TIME_INTERVAL 추가
    return (lastTimeInMinutes - firstTimeInMinutes) + ROOM_TIME_INTERVAL;
  };

  const getTimeInterval = (availableRooms) => {
    if (availableRooms.length < 2) return 30;
  
    const [time1, time2] = availableRooms
      .slice(0, 2)
      .map(room => {
        const [hours, minutes] = room.time.split(':').map(Number);
        return hours * 60 + minutes;
      });
    
    return time2 - time1;
  };

  const calculateTimeRange = (timeSlots) => {
    if (!timeSlots.length) return '';
    
    const sortedSlots = [...timeSlots].sort((a, b) => {
      const [aHours, aMinutes] = a.time.split(':').map(Number);
      const [bHours, bMinutes] = b.time.split(':').map(Number);
      return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
    });
  
    const startTime = formatTime(sortedSlots[0].time);
    
    // 시작 시간에서 총 시간을 더해서 종료 시간 계산
    const [startHours, startMinutes] = sortedSlots[0].time.split(':').map(Number);
    const totalMinutes = calculateTotalMinutes(timeSlots);
    
    const endTotalMinutes = (startHours * 60 + startMinutes) + totalMinutes;
    const endHours = Math.floor(endTotalMinutes / 60);
    const endMinutes = endTotalMinutes % 60;
    
    const endTime = formatTime(
      `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`
    );
    
    return `${startTime} - ${endTime}`;
  };

  const calculateSlotEndTime = (time, interval) => {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + interval;
    const endHours = Math.floor(totalMinutes / 60);
    const endMinutes = totalMinutes % 60;
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const fetchAvailableRooms = async (date, room) => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/user/available-rooms/', {
        params: {
          date: date,
          room_id: room.id
        }
      });
  
      const { all_slots, available_slots } = response.data;
      
      setAllTimeSlots(all_slots.sort((a, b) => a.time.localeCompare(b.time)));
      setAvailableRooms(available_slots.sort((a, b) => a.time.localeCompare(b.time)));
  
      if (initialState.skipTimeSelection && initialState.selectedTimes) {
        const slots = initialState.selectedTimes
          .map(selectedTime => all_slots.find(t => t.time === selectedTime.time))
          .filter(Boolean);
        
        if (slots.length > 0) {
          setSelectedTimeSlots(slots);
        }
      }
    } catch (error) {
      toast.error('가용 시간을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate && selectedRoom) {
      fetchAvailableRooms(selectedDate, selectedRoom);
    }
  }, [selectedDate, selectedRoom?.id]);

  const getHourOptions = () => {
    if (!allTimeSlots.length) return [];
    
    const hours = new Set(
      allTimeSlots.map(slot => {
        const [hours] = slot.time.split(':').map(Number);
        return hours;
      })
    );
    
    return Array.from(hours).sort((a, b) => a - b);
  };
  
  const getMinuteSlots = (hour) => {
    return allTimeSlots.filter(slot => {
      const [slotHour] = slot.time.split(':').map(Number);
      return slotHour === hour;
    });
  };

  const isConsecutiveTime = (existingSlots, newSlot) => {
    if (existingSlots.length === 0) return true;

    const selectedIndices = [...existingSlots, newSlot].map(slot => 
      allTimeSlots.findIndex(t => t.time === slot.time)
    ).sort((a, b) => a - b);

    for (let i = 1; i < selectedIndices.length; i++) {
      if (selectedIndices[i] - selectedIndices[i-1] !== 1) {
        return false;
      }
    }
    return true;
  };

  const isAllAvailable = (startIndex, endIndex) => {
    // 시작과 끝 사이의 모든 시간이 예약 가능한지 확인
    for (let i = startIndex; i <= endIndex; i++) {
      const slot = allTimeSlots[i];
      if (!availableRooms.some(r => r.time === slot.time)) {
        return false;
      }
    }
    return true;
  };

  const handleTimeSlotClick = (room) => {
    setSelectedTimeSlots(prev => {
      const isSelected = prev.some(slot => slot.id === room.id);
      
      if (isSelected) {
        const indices = prev.map(slot => 
          allTimeSlots.findIndex(t => t.time === slot.time)
        );
        const clickedIndex = allTimeSlots.findIndex(t => t.time === room.time);
        
        if (clickedIndex !== Math.min(...indices) && clickedIndex !== Math.max(...indices)) {
          toast.error('중간 시간은 해제할 수 없습니다.');
          return prev;
        }
        
        return prev.filter(slot => slot.id !== room.id);
      }
  
      // 새로운 선택 로직
      if (prev.length === 0) {
        return [room];
      }
  
      const existingIndex = allTimeSlots.findIndex(t => t.time === prev[0].time);
      const newIndex = allTimeSlots.findIndex(t => t.time === room.time);
      
      const startIndex = Math.min(existingIndex, newIndex);
      const endIndex = Math.max(existingIndex, newIndex);
  
      if (!isAllAvailable(startIndex, endIndex)) {
        toast.error('선택한 범위 내에 예약할 수 없는 시간이 포함되어 있습니다.');
        return prev;
      }
  
      return allTimeSlots
        .slice(startIndex, endIndex + 1)
        .map(slot => availableRooms.find(r => r.time === slot.time))
        .filter(Boolean);
    });
  };

  const canGoNext = () => {
    switch (step) {
      case 1:
        return !!selectedDate;
      case 2:
        return !!selectedRoom;
      case 3:
        return !!selectedHour;
      case 4:
        return selectedTimeSlots.length > 0;
      case 5:
        return true;
      default:
        return false;
    }
  };

  const handlePrevious = () => {
    // skipTimeSelection이 true인 경우 대시보드로 돌아가기
    if (initialState.skipTimeSelection) {
      navigate('/dashboard');
      return;
    }
  
    // 기존 로직
    if (step > 1) {
      if (step === 5) {
        setSelectedTimeSlots([]);
      } else if (step === 4) {
        setSelectedHour(null);
      } else if (step === 3) {
        setSelectedRoom(null);
      } else if (step === 2) {
        setSelectedDate('');
      }
      setStep(prev => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (canGoNext() && step < 5) {
      setStep(prev => prev + 1);
    }
  };

  const validateVisitorInfo = () => {
    if (!visitorInfo.title.trim()) {
      toast.error('미팅 목적을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.name.trim()) {
      toast.error('방문자명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.company.trim()) {
      toast.error('소속을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.phone.trim() || visitorInfo.phone.replace(/-/g, '').length !== 11) {
      toast.error('올바른 연락처를 입력해주세요.');
      return false;
    }
    if (!visitorInfo.count || visitorInfo.count < 1 || visitorInfo.count > selectedRoom.size) {
      toast.error(`방문 인원은 1명 이상 ${selectedRoom.size}명 이하여야 합니다.`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateVisitorInfo()) return;
  
    try {
      const dateObj = dates.find(d => d.date === selectedDate);
      const ROOM_TIME_INTERVAL = 10;
      
      // 시간대 정렬
      const sortedSlots = [...selectedTimeSlots].sort((a, b) => {
        const [aHours, aMinutes] = a.time.split(':').map(Number);
        const [bHours, bMinutes] = b.time.split(':').map(Number);
        return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
      });
  
      await api.post('/api/user/reserve-meeting/', {
        visitor: visitorInfo,
        room_id: selectedRoom.id,
        event_date_id: dateObj.id,
        note: note.trim(),
        time_slots: sortedSlots.map((slot) => ({
          available_room_id: slot.id,
          duration: ROOM_TIME_INTERVAL
        }))
      });
      
      toast.success(
        '미팅룸 예약이 신청되었습니다. \n관리자 검토 후 이메일로 승인 여부를 안내드립니다.',
        {
          duration: 5000,
        }
      );
      navigate('/dashboard', { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.error || '예약 신청에 실패했습니다.');
    }
  };

  if (isLoading) {
    return (
      <div className="container max-w-md min-h-screen p-4 mx-auto">
        <div className="flex items-center justify-center h-screen">
          <div className="w-6 h-6 border-2 rounded-full border-t-transparent border-sky-600 animate-spin" />
        </div>
      </div>
    );
  }

  const renderStepContent = () => {

    const timeInterval = getTimeInterval(availableRooms);

    switch (step) {
      case 1:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CalendarDays className="w-5 h-5" />
                날짜 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-3">
                {dates.map((date) => (
                  <button
                    key={date.id}
                    onClick={() => setSelectedDate(date)}
                    className={`flex items-center justify-between w-full p-4 text-left border rounded-lg hover:bg-gray-50 ${
                      selectedDate === date.date ? 'border-sky-500 bg-sky-50' : ''
                    }`}
                  >
                    <span className="font-medium">
                      {selectedDate}
                    </span>
                  </button>
                ))}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

        case 2:
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Building2 className="w-5 h-5" />
                  미팅룸 선택
                </CardTitle>
              </CardHeader>
              <CardContent>
                <select
                  value={selectedRoom ? selectedRoom.id : ''}
                  onChange={(e) => {
                    const room = rooms.find(r => r.id === parseInt(e.target.value));
                    setSelectedRoom(room);
                  }}
                  className="w-full px-4 py-3 text-lg border rounded-lg focus:ring-2 focus:ring-sky-500"
                >
                  <option value="">미팅룸을 선택하세요</option>
                  {rooms.map((room) => (
                    <option key={room.id} value={room.id}>
                       {room.name} ({room.floor}층 {room.size}인실)
                    </option>
                  ))}
                </select>
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    currentStep={step}
                    canGoNext={canGoNext()}
                  />
                </div>
              </CardContent>
            </Card>
          );
  
        case 3:
          const hourOptions = getHourOptions();
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Clock className="w-5 h-5" />
                  시간 선택
                </CardTitle>
                <CardDescription>
                  {selectedRoom.name} {selectedRoom.floor}층 - {selectedRoom.size}인실
                  <p className="mt-1 text-sm text-gray-500">원하시는 시간을 선택해주세요.</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-3 gap-3">
                  {hourOptions.map((hour) => {
                    // 해당 시간대의 마지막 시간으로 체크
                    const formattedLastMinute = `${hour.toString().padStart(2, '0')}:59`;
                    const isPast = !isTimeSelectable(selectedDate, formattedLastMinute);
                    const hasAvailableSlots = getMinuteSlots(hour).some(slot => 
                      availableRooms.some(r => r.time === slot.time)
                    );
                    
                    return (
                      <button
                        key={hour}
                        onClick={() => hasAvailableSlots && !isPast && setSelectedHour(hour)}
                        disabled={!hasAvailableSlots || isPast}
                        className={`
                          flex items-center justify-center w-full p-4 text-center border rounded-lg
                          ${!isPast && hasAvailableSlots ? 'hover:bg-gray-50' : 'opacity-50 bg-gray-100 cursor-not-allowed'}
                          ${selectedHour === hour ? 'border-sky-500 bg-sky-50' : ''}
                          ${isPast ? 'line-through text-gray-400' : ''}
                        `}
                      >
                        <span className="font-medium">
                          {`${hour.toString().padStart(2, '0')}:00`}
                          {isPast && (
                            <span className="block text-xs text-gray-400">
                              지난 시간
                            </span>
                          )}
                        </span>
                      </button>
                    );
                  })}
                </div>
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    currentStep={step}
                    canGoNext={!!selectedHour}
                  />
                </div>
              </CardContent>
            </Card>
          );
  
        case 4:
          const minuteSlots = getMinuteSlots(selectedHour);
          
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Clock className="w-5 h-5" />
                  시간 선택
                </CardTitle>
                <CardDescription>
                  {selectedRoom.floor}층 {selectedRoom.name} - {selectedHour}시
                  <p className="mt-1 text-sm text-gray-500">원하시는 회의 시간을 모두 선택해주세요.</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-2 gap-3">
                  {minuteSlots.map((slot) => {
                    const isAvailable = availableRooms.some(r => r.time === slot.time);
                    const isPast = !isTimeSelectable(selectedDate, slot.time);
                    const endTime = calculateSlotEndTime(slot.time, timeInterval);
                    
                    return (
                      <button
                        key={slot.id}
                        onClick={() => isAvailable && !isPast && handleTimeSlotClick(slot)}
                        disabled={!isAvailable || isPast}
                        className={`
                          flex items-center justify-center w-full p-4 text-center border rounded-lg 
                          ${!isPast && isAvailable ? 'hover:bg-gray-50' : 'opacity-50 bg-gray-100 cursor-not-allowed'} 
                          ${selectedTimeSlots.some(s => s.id === slot.id) ? 'border-sky-500 bg-sky-50' : ''}
                          ${isPast ? 'line-through text-gray-400' : ''}
                        `}
                      >
                        <span className="font-medium">
                          <div>{formatTime(slot.time)} - {formatTime(endTime)}</div>
                          {isPast && (
                            <span className="block text-xs text-gray-400">
                              지난 시간
                            </span>
                          )}
                        </span>
                      </button>
                    );
                  })}
                </div>
                {selectedTimeSlots.length > 0 && (
                  <div className="p-3 mt-4 border rounded-lg bg-sky-50 border-sky-100">
                    <div className="text-sm font-medium text-sky-900">선택된 시간</div>
                    <div className="mt-1 text-sm text-sky-700">
                      {calculateTimeRange(selectedTimeSlots, timeInterval)}
                      <div className="mt-1 text-xs text-sky-600">
                        총 {calculateTotalMinutes(selectedTimeSlots)}분
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    currentStep={step}
                    canGoNext={selectedTimeSlots.length > 0}
                  />
                </div>
              </CardContent>
            </Card>
          );
  
        case 5:
          return selectedRoom ? (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Users className="w-5 h-5" />
                  방문자 정보
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="p-5 mb-6 border rounded-lg bg-white/50 backdrop-blur-sm">
                  <h3 className="mb-3 text-sm font-medium text-gray-800">예약 정보</h3>
                  <dl className="grid gap-3">
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <CalendarDays className="w-4 h-4" />
                          <span>날짜</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        {selectedDate}
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Building2 className="w-4 h-4" />
                          <span>미팅룸</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        <div className="text-right">
                          <div>{selectedRoom.name}</div>
                          <div className="text-xs text-gray-500">{selectedRoom.floor}층 {selectedRoom.size}인실</div>
                        </div>
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Clock className="w-4 h-4" />
                          <span>시간</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        <div className="text-right">
                          <div>
                            {calculateTimeRange(selectedTimeSlots, timeInterval)}
                          </div>
                          <div className="text-xs text-gray-500">
                            총 {calculateTotalMinutes(selectedTimeSlots)}분
                          </div>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
                
                <VisitorForm 
                  visitorInfo={visitorInfo}
                  onChange={setVisitorInfo}
                  type="room"
                  maxCount={selectedRoom?.size}
                />
                <div className="mt-4">
                  <label className="block mb-1 text-sm font-medium">추가 요청사항</label>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full h-24 px-4 py-3 border rounded-lg resize-none focus:ring-2 focus:ring-sky-500"
                    placeholder="추가 요청사항이 있다면 입력해주세요."
                  />
                </div>
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleSubmit}
                    currentStep={step}
                    isLastStep={true}
                    canGoNext={true}
                  />
                </div>
              </CardContent>
            </Card>
          ) : null;
        
        default:
          return null;
      }
    };
  
    return (
      <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
        <PageHeader 
          title="미팅룸 예약" 
          onBack={handlePrevious}
        />
        <StepProgress currentStep={step} />
        {renderStepContent()}
      </div>
    );
  }